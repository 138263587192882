import v3 from "../v3";

export const triangle = function ({ a, b, c, color, wireframe = false }) {
    let vertices = [];

    if (!a.height) a.height = 0;
    if (!b.height) b.height = 0;
    if (!c.height) c.height = 0;

    vertices = vertices.concat(
        [a.lat, a.lng, a.height],
        [b.lat, b.lng, b.height],
        [c.lat, c.lng, c.height],
    );

    // one normal
    const A = new v3(a.lat, a.lng, a.height);
    const B = new v3(b.lat, b.lng, b.height);
    const C = new v3(c.lat, c.lng, c.height);
    const D1 = B.minus(A);
    const D2 = C.minus(A);
    const normal = D1.times(D2).normalize();
      
    if (wireframe) {
        vertices = vertices.concat(
            [a.lat, a.lng, a.height],
        );
    }

    const objects = [{
        mode: wireframe ? 'LINE_STRIP' : 'TRIANGLES',
        start: 0,
        count: wireframe ? 4 : 3,
        color: color
    }];

    return {
        vertices,
        normals: [normal, normal, normal],
        objects,
    }
}