export default {
  container(className) {
    const el = document.createElement('div');
    el.classList.add('mapboxgl-ctrl');
    el.classList.add('mapboxgl-ctrl-group');
    el.classList.add(className);
    return el;
  },

  button(text, active) {
    const el = document.createElement('button');
    el.setAttribute('type', 'button');
    el.textContent = text;
    if (active) el.classList.add('active');
    return el;
  }
}