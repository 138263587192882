<template>
  <div id="page" class="aside-main">
    <!-- LEFT PANEL -->
    <aside>
      <fieldset class="search vflex">
        <legend>Points of Interests</legend>
        <select v-model="poi.tag">
          <option value="">Tag ?</option>  
          <option v-for="(values, tag) in stats.tags" :key="tag" :value="tag">{{tag}}</option>
        </select>
        <div v-if="poi.tag">
          <select v-model="poi.value" @change="getPois">
            <option value="">- Value -</option>  
            <option v-for="value in stats.tags[poi.tag]" :key="value.v" :value="value.v">{{value.v}} ({{value.n}})</option>
          </select>
        </div>
        <a href="javascript:void(0)" style="color: #888; font-size: 80%" v-if="hideResult" @click="poi.tag=''; poi.value=''; getPois()">(x) Hide the results</a>
        <a href="javascript:void(0)" style="color: #888; font-size: 80%" v-if="poi.tag && poi.value" @click="downloadPois">Download GeoJSON</a>
        {{error}}
      </fieldset>
      <ul style="max-height: 140px; overflow:auto">
        <li v-for="svg in missingSvgss" style="font-size: 11px" :key="svg">{{svg}}</li>
        <li v-for="svg in missingSvgss" style="font-size: 11px" :key="svg">{{svg}}</li>
      </ul>
    </aside>

    <!-- MAP -->
    <main id="map" @keypress="mapKeyPress">
      <div id="info">
        [bbox:{{bbox}}]&nbsp;&nbsp;
        Zoom:{{zoom}}&nbsp;&nbsp;
        <span title="Press 'C' while hovering map to copy to clipboard">Lat:{{lat}}&nbsp;Lng:{{lng}}</span>
      </div>
      <MglMap 
        :accessToken="accessToken" 
        :mapStyle="mapStyle" 
        :attributionControl="false"
        :attribution="false"
        :hash="true"
        :tileBoundaries="true"
        @load="onMapLoad"
      >
        <MglFullscreenControl position="top-right" />
        <MglNavigationControl position="top-right" />
        <MglGeolocateControl position="top-right" :positionOptions="{enableHighAccuracy:true}" :trackUserLocation="true" />
        <MglAttributionControl position="bottom-right" />
        <MglScaleControl position="bottom-left" />
      </MglMap>
    </main>
  </div>

</template>

<script>
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglGeolocateControl,
  MglFullscreenControl,
  MglScaleControl
} from "vue-mapbox";
import { AllStyles } from "@/mapbox/styles";
import { addMobilePitchGesture } from "@/assets/p";
import MyControls from "../mapbox/controls";
import { Misc } from '../tools.js';
import Str from '@/common/helpers/Str.js';
import * as MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import zLayer from '@/mapbox/addons/zLayer';

import ICONS from "@/assets/icons";
import CONF from "../common/config.js"

const directions = new MapboxDirections({
  accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2tjOTFvOGg2MTBibjJ6cDhmNWhkYmFhaiJ9.qonyr0JcWzkfcQqWFtmCHQ',
  unit: 'metric',
  profile: 'mapbox/cycling'
});

export default {
  components: {
    MglMap,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl,
    MglFullscreenControl,
    MglScaleControl
  },
  data() {
    return {
      CONF,
      accessToken: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2tjOTFvOGg2MTBibjJ6cDhmNWhkYmFhaiJ9.qonyr0JcWzkfcQqWFtmCHQ',
      mapStyle: AllStyles['WhereStreets'].style,
      poi: {
        tag: '',
        value: '',
        geojson: null,
      },
      stats: {},
      streetsQ: '',
      missingSvgs: [],
      searching: false,
      zoom: 0,
      bbox: '',
      lat: 0,
      lng: 0,
      error: '',
      searchTimeout: null,
      hideResult: false,
    };
  },
  created() {
    // load list of amenities
    this.$api.get(`/db/stats`).then(j => this.stats = j.data);
  },
  computed: {
    missingSvgss() {
      return [...this.missingSvgs].sort();
    }
  },
  methods: {
    onMapLoad(event) {
      const scope = this;
      const map = event.map;
      this.map = event.map;
      map.vue = scope;

      document.querySelector('canvas.mapboxgl-canvas').focus();

      // for debugging in browser
      window.map = event.map;

      // faster scroll with mouse wheel
      map.on('wheel', function(e) {
        if (e.originalEvent.shiftKey) map.scrollZoom.setWheelZoomRate(1/400);
        else map.scrollZoom.setWheelZoomRate(1/100);
        return true;
      });
      map.scrollZoom.setWheelZoomRate(1/100);

      // add myControls
      map.addControl(new MyControls.SearchControl(), 'top-left');
      map.addControl(new MyControls.StyleSelectorControl({ styles: Object.values(AllStyles), }), 'top-left');
      map.addControl(new MyControls.LanguageSelectorControl());
      if (!Misc.isMobile()) {
        map.addControl(new MyControls.ExternalEditorsControl());
        map.addControl(new MyControls.ShowTilesCoordinatesControl());
      }
      map.addControl(new MyControls.LayersControl(), 'top-left');
      map.addControl(new MyControls.QueryFeatureControl());
      map.addControl(new MyControls.ControlTogglerControl({ control: directions, label: 'A>B' }));
      
      addMobilePitchGesture(map);

      // if centered to [0, 0] pan to Tunisia
      if (map.getCenter().lat < 0.01) {
        map.fitBounds([[0, 29.25], [19.8, 37.98]]);
      }
      
      /**
        * Display Mouse Coordinates
        * Retrigger Search
        */
      map.on('mousemove', function(e) {
        let precision = Math.round(map.getZoom() / 3);
        scope.lat = e.lngLat.lat.toFixed(precision);
        scope.lng = e.lngLat.lng.toFixed(precision);
      });

      const missingImages = [];
      map.on('styleimagemissing', (e) => {

        // Already Being Created ?
        const id = e.id;
        if (missingImages.includes(id)) return;
        missingImages.push(id);

        
        const saveImg = (vb, size, paths, icon) => {

          let fg;
          if (['amenity-parking', 'amenity-fuel'].includes(icon)) {
            fg = '<g fill="#06f">' + paths + '</g>';
          } else if (['shop-car', 'shop-car_repair', 'amenity-car_rental'].includes(icon)) {
            fg = '<g fill="#17e">' + paths + '</g>';
          } else if (['amenity-cafe', 'amenity-restaurant', 'shop-bakery', 'shop-butcher',
                     'amenity-bar', 'amenity-fast_food', 'amenity-ice_cream'].includes(icon)) {
            fg = '<g fill="#cc6529">' + paths + '</g>';
          } else if (['amenity-clinic', 'amenity-hospital', 'amenity-pharmacy', 'amenity-doctors'].includes(icon)) {
            fg = '<g fill="#f33">' + paths + '</g>';
          } else if (['amenity-bank'].includes(icon)) {
            fg = '<g fill="#183">' + paths + '</g>';
          } else {
            fg = '<g fill="#444">' + paths + '</g>';
          }

          let bg = '<g stroke="#fff" stroke-width="1">' + paths + '</g>';

          const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${size}px" height="${size}px" viewBox="${vb[0]} ${vb[1]} ${vb[2]} ${vb[3]}">${bg}${fg}</svg>`;
          const imgSrc = 'data:image/svg+xml;base64,' + btoa(svg);
          const img = document.createElement('img');
          
          // document.querySelector('aside').appendChild(img);
          // img.title = id;

          img.addEventListener('load', () => map.addImage(id, img));
          img.src = imgSrc;
        }

        const name = id.substr(0, id.length - 3);
        const size = id.substr(-2) === 'sm' ? 11 : 15;

        const icon = ICONS[name + '-' + size] || ICONS[name];

        if (icon) {
          this.$store.dispatch('getIcon', { path: icon, vue: this}).then ( data => {
            saveImg(data.viewBox, size, data.paths, name);
          })
        } else {
          //this.missingSvgs.push(id);
          console.warn('Missing Icon: ' + id);
          let path = '<path d="m289.999 201.668c-48.705 0-88.329 39.625-88.329 88.33s39.624 88.329 88.329 88.329 88.329-39.624 88.329-88.329-39.624-88.33-88.329-88.33z" fill="param(fill) #000" fill-opacity="255" stroke="param(outline) #FFF" stroke-opacity="255" stroke-width="param(outline-width) 0"/>';
          saveImg([0, 0, 580, 580], 12, path, name);
        }

      });
      map.on('style.load', () => missingImages.splice(0, missingImages.length));


      /**
      * Display Bbox
      */
      function updateBbox() {
        let bbox = map.getBounds().toArray();
        let precision = Math.round(map.getZoom() / 3);
        bbox = [
          bbox[0][1].toFixed(precision), 
          bbox[0][0].toFixed(precision),
          bbox[1][1].toFixed(precision),
          bbox[1][0].toFixed(precision)
        ];
        scope.bbox = bbox.join(',');
        scope.zoom = map.getZoom().toFixed(1);
      }
      map.on('move', updateBbox);
      map.on('move', updateBbox);
      updateBbox();

      map.on('style.load', z);
      z();

      function z() {
        const zlayer = new zLayer('layerId', {
          defaultColor: [1, 0, 1, 1],
        });
        zlayer.addTriangle({
            a: { lng: 10.29124, lat: 36.90305 },
            b: { lng: 10.29118, lat: 36.90312 },
            c: { lng: 10.29118, lat: 36.90312, height: 1 },
            color: [1,0,0,0.7]
        });

        zlayer.addTriangle({
            a: { lng: 10.29124, lat: 36.90308 },
            b: { lng: 10.29118, lat: 36.90312 },
            c: { lng: 10.29118, lat: 36.90312, height: 1 },
            color: [1,0,0,0.7]
        });

        zlayer.addTriangle({
            a: { lng: 10.29124, lat: 36.90310 },
            b: { lng: 10.29118, lat: 36.90312 },
            c: { lng: 10.29118, lat: 36.90312, height: 1 },
            color: [1,0,0,0.7]
        });

        zlayer.addTriangle({
            a: { lng: 10.29124, lat: 36.90315 },
            b: { lng: 10.29118, lat: 36.90312 },
            c: { lng: 10.29118, lat: 36.90312, height: 1 },
            color: [1,0,0,0.7]

        });
        zlayer.addTriangle({
            a: { lng: 0, lat: 0 },
            b: { lng: 1, lat: 1 },
            c: { lng: 1, lat: 0 },
        });
        // zlayer.addPolygon({
        //     coordinates: [
        //         { lng: 10.2910, lat: 36.9030 },
        //         { lng: 10.2910, lat: 36.9031 },
        //         { lng: 10.2911, lat: 36.9031 },
        //         { lng: 10.2911, lat: 36.9030 },
        //         { lng: 10.29105, lat: 36.90305 },
        //     ], 
        //     color: [1,0,0,0.8]
        // });
        
        // zlayer.addCircle({
        //   center: { lng: 10.2909, lat: 36.9030 },
        //   radius: 10,
        //   segments: 60,
        //   color: [1,0,0,1]
        // });

        zlayer.addSphere({
          center: { lng: 10.295852, lat: 36.907294, height: 6 },
          radius: 10,
          rings: 20,
          segments: 24,
          dome: true,
          color: [1,1,1,1],
        });

        zlayer.addCylinder({
          center: { lng: 10.290863, lat: 36.903130 },
          radius: 10,
          height: 10,
          segments: 16,
          color: [1,1,1,1],
          wireframe: false,
        });

        // zlayer.addCorrugatedQuad({
        //   a: { lng: 10.2895939, lat: 36.9085779, height: 1 },
        //   b: { lng: 10.2895268, lat: 36.9086498, height: 1 },
        //   c: { lng: 10.2895469, lat: 36.9086587, height: 1 },
        //   d: { lng: 10.2896127, lat: 36.9085873, height: 1 },
        //   periods: 16, 
        //   periodResolution: 16, 
        //   h: 0.1, 
        //   color: [0.9,0.3,0.1,0.8],
        //   wireframe: 0,
        // });

        map.addLayer(zlayer);

      }

    },
    getPois() {
      this.hideResult = false;
      this.poi.geojson = null;
      const map = this.map;
      const { tag, value } = this.poi;

      if (map.getSource('pois')) {
        map.removeLayer('pois')
        map.removeLayer('pois-label')
        map.removeSource('pois')
      }

      if (!tag || !value) return;

      this.searching = true;
      this.$api
        .get(`/db/tagValue?t=${tag}&v=${value}`)
        .then(j => {
          this.searching = false;
          this.hideResult = true;

          this.poi.geojson = j.data
          map.addSource('pois', { 'type': 'geojson', data: j.data });
          map.addLayer({
            'id': 'pois',
            'source': 'pois',
            'type': 'circle',
            'paint': {
              'circle-radius': [ "interpolate", [ "exponential", 1.5 ], [ "zoom" ], 6, 2, 16, 6 ],
              'circle-color': '#B42222'
            }
          });
          map.addLayer({
            'id': 'pois-label',
            'source': 'pois',
            'type': 'symbol',
            "minzoom": 10,
            'layout': {
              'text-field': ['get', 'name'],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'text-size': 12,
              'text-offset': [0, 0.6],
              'text-anchor': 'top',
            },
            'paint': {
              'text-color': '#B42222',
              'text-halo-color': '#ffffff',
              'text-halo-width': 2,
            }
          });

        })
    },
    downloadPois() {
      let { geojson, tag, value } = this.poi;

      tag = tag.replace(/:/g, '_');
      value = value.replace(/:/g, '_');

      Str.download(JSON.stringify(geojson, null, '  '), `${tag}-${value}.geojson`)
    },
    getBBox() {
      let bbox = window.map.getBounds().toArray();
      let precision = Math.round(window.map.getZoom() / 3);
      return [
        bbox[0][1].toFixed(precision), 
        bbox[0][0].toFixed(precision),
        bbox[1][1].toFixed(precision),
        bbox[1][0].toFixed(precision)
      ].join(',');
    },
    mapKeyPress(e) {
      if (e.key === 'c' && document.activeElement && (document.activeElement.tagName === 'CANVAS')) Str.copyToClipboard('lng: ' + this.lng + ', lat: ' + this.lat);
      else if (e.key === 'f') {
        const el = document.querySelector('.mapboxgl-ctrl-search input[type=search]');
        if (document.activeElement !== el) {
          el.focus();
          e.preventDefault();
          return false;
        }
      }
    }
  },
  watch: {
    // fix map size when navigating to another component and back
    $route(to, from){
      if (to.name === 'home') {
        this.map.resize();
      } else if ( (from.name === 'home') && (to.name === 'style-editor')) {
       this.$router.push({ name: 'style-editor', hash: document.location.hash })
      }
    },
    'poi.tag'() {
      this.poi.value = ''; 
    }
  }
};
</script>

<style>
@import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
@import "../../node_modules/@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css";
</style>
<style scoped lang="scss">
#map {
  position: relative;
}

fieldset.search {
  margin-bottom: 1em;
  border: 1px solid #888;
  padding: 0.5em;

  legend {
    padding: 0 0.5em;
  }
  select {
    width: 100%;
  }
  input[type=checkbox] {
    margin-right: 4px;
  }
  ul {
    margin-left: 0.5em;
    li {
      padding: 0.2em 0;
    }
  }
}

select:focus {
  border-color: #00ff72;
  outline: 0;
}

@media only screen and (max-width: 600px) {
  aside {
    height: 40px;
  }
  #info {
    display: none;
  }
  .search {
    & > label {
      display: none;
    }
    input, select {
      max-width: 40%;
    }
    select {
      margin-right: 20px;
    }
  }
}

</style>
<style lang="scss">



#info { 
  position: absolute; 
  bottom: 0; left: 160px;
  background: hsla(0,0%,100%,.5); color: rgba(0,0,0,.75);
  padding: 2px; 
  font-family: monospace; 
  font-size: 12px;
  z-index: 1;
}
#info:hover {
  background: hsl(0,0%,100%); color: rgb(0,0,0);
}

.map-overlay {
  font: bold 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  position: absolute;
  width: 25%;
  bottom: 100px;
  left: 0;
  padding: 10px;

  .map-overlay-inner {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin: 0 0 10px;
  }

  input {
    background-color: transparent;
    display: inline-block;
    width: 100%;
    position: relative;
    margin: 0;
    cursor: ew-resize;
  }
}

.vflex {
  display: flex;
  flex-direction: column;
  gap: 0.7em;
}

</style>
