/**
 * 
 * @todo Fix circle which looks like an ellipse
 */

import { centerRadius2Circle } from "./functions";
import v3 from "../v3";

// Sphere made out of triangles doubling every ring
export const sphereTriangles = function ({ center, radius, rings, segments, color }) {
    let vertices = [];
    const objects = [];

    let index = vertices.length / 3;
    let k = 0;


    const points = {};
    for (let i = 1; i <= rings; i++) {
        points[i] = centerRadius2Circle(center, radius * Math.sin((Math.PI / 2) * i / rings), segments * Math.pow(2, i - 1));
    }

    const ringHeight = [];
    for (let i = 0; i <= rings; i++) {
        ringHeight[i] = center.height + (radius * Math.cos((i * Math.PI / 2) / rings) / 3);
    }

    // first spokes
    k = 0;
    points[1].forEach(([lat, lng]) => {
        vertices = vertices.concat(
            [center.lat, center.lng, ringHeight[0]],
            [lat, lng, ringHeight[1]]
        );
        k += 2;
    });
    objects.push({
        mode: 'LINES',
        start: index,
        count: k,
        color: color || [0, 0, 0, 1]
    });

    // next spokes
    index = vertices.length / 3;
    k = 0;
    for (let ring = 1; ring < rings; ring++) {
        for (let i = 0; i < points[ring].length - 1; i++) {
            const RI = i * 2;
            const NI = points[ring + 1].length;

            const a = points[ring][i];
            const b = points[ring + 1][RI];
            const c = points[ring + 1][RI + 1];
            const d = points[ring + 1][(RI + NI - (i === 0 ? 2 : 1)) % NI];

            vertices = vertices.concat([
                a[0], a[1], ringHeight[ring],
                b[0], b[1], ringHeight[ring + 1],
                a[0], a[1], ringHeight[ring],
                c[0], c[1], ringHeight[ring + 1],
                a[0], a[1], ringHeight[ring],
                d[0], d[1], ringHeight[ring + 1],
            ]);
            k += 6;
        }
    }
    objects.push({
        mode: 'LINES',
        start: index,
        count: k,
        color: color || [0, 0, 0, 1]
    });

    // next ringss
    for (let ring = 1; ring <= rings; ring++) {
        index = vertices.length / 3;
        k = 0;
        for (let i = 0; i < points[ring].length; i++) {
            vertices = vertices.concat(
                [points[ring][i][0], points[ring][i][1], ringHeight[ring]],
            );
            k += 1;
        }
        objects.push({
            mode: 'LINE_STRIP',
            start: index,
            count: k,
            color: color || [0, 0, 0, 1]
        });
    }

    return { vertices, objects };

}

// Sphere made out of triangles doubling every ring
export const sphere = function ({ center, radius, rings, segments, color, dome, wireframe }) {
    let vertices = [];
    let normals = [];
    const objects = [];
    const heightRatio = 0.45;
    let start = 0;
    let count;
    color = color || [1,1,1,1];

    const angleDelta = Math.PI / rings * (dome ? 0.5 : 1);

    const points = {};
    for (let i = 1; i <= rings; i++) {
        points[i] = centerRadius2Circle(center, radius * Math.sin(angleDelta * i), segments);
    }

    const ringHeight = [];
    for (let i = 0; i <= rings; i++) {
        ringHeight[i] = center.height + (radius * Math.cos(angleDelta * i) * heightRatio);
    }

    if (wireframe) {
        // first spokes
        count = 0;
        points[1].forEach(([lat, lng]) => {
            vertices = vertices.concat([
                center.lat, center.lng, ringHeight[0],
                lat, lng, ringHeight[1]
            ]);
            count += 2;
        });
        objects.push({ mode: 'LINES', start, count, color });

        // next spokes
        start = vertices.length / 3;
        count = 0;
        for (let ring = 1; ring < rings; ring++) {
            for (let i = 0; i < points[ring].length - 1; i++) {

                const a = points[ring][i];
                const b = points[ring + 1][i];
                const c = points[ring + 1][i + 1];
                const d = points[ring][i + 1];

                vertices = vertices.concat([
                    a[0], a[1], ringHeight[ring],
                    b[0], b[1], ringHeight[ring + 1],
                    d[0], d[1], ringHeight[ring],
                    c[0], c[1], ringHeight[ring + 1],
                ]);
                count += 4;
            }
        }
        objects.push({ mode: 'LINES', start, count, color });

        // next ringss
        for (let ring = 1; ring <= rings; ring++) {
            start = vertices.length / 3;
            count = 0;
            for (let i = 0; i < points[ring].length; i++) {
                vertices = vertices.concat(
                    [points[ring][i][0], points[ring][i][1], ringHeight[ring]],
                );
                count += 1;
            }
            objects.push({ mode: 'LINE_STRIP', start, count, color });
        }
    } else { // not wireframe
        
        // first spokes
        vertices = vertices.concat([ center.lat, center.lng, ringHeight[0] ]);
        normals.push(new v3(0, 0, (ringHeight[0] - center.height)/60000000));

        count = 1;
        points[1].forEach(([lat, lng]) => {
            vertices = vertices.concat([ lat, lng, ringHeight[1] ]);
            normals.push(new v3(lat - center.lat, lng - center.lng, (ringHeight[1] - center.height) / 60000000));
            count++;
        });
        objects.push({ mode: 'TRIANGLE_FAN', start, count, color });

        // // next spokes
        start = vertices.length / 3;
        count = 0;
        for (let ring = 1; ring < rings; ring++) {
            for (let i = 0; i < points[ring].length - 1; i++) {

                const a = points[ring][i];
                const b = points[ring + 1][i];
                const c = points[ring + 1][i + 1];
                const d = points[ring][i + 1];

                vertices = vertices.concat([
                    a[0], a[1], ringHeight[ring],
                    b[0], b[1], ringHeight[ring + 1],
                    d[0], d[1], ringHeight[ring],
                    c[0], c[1], ringHeight[ring + 1],
                ]);
                normals.push(new v3(a[0] - center.lat, a[1] - center.lng, (ringHeight[ring] - center.height)/60000000));
                normals.push(new v3(b[0] - center.lat, b[1] - center.lng, (ringHeight[ring + 1] - center.height)/60000000));
                normals.push(new v3(c[0] - center.lat, c[1] - center.lng, (ringHeight[ring] - center.height)/60000000));
                normals.push(new v3(d[0] - center.lat, d[1] - center.lng, (ringHeight[ring + 1] - center.height)/60000000));

                count += 4;
            }
        }
        objects.push({ mode: 'TRIANGLE_STRIP', start, count, color });

        // // next ringss
        // for (let ring = 1; ring <= rings; ring++) {
        //     start = vertices.length / 3;
        //     count = 0;
        //     for (let i = 0; i < points[ring].length; i++) {
        //         vertices = vertices.concat(
        //             [points[ring][i][0], points[ring][i][1], ringHeight[ring]],
        //         );
        //         count += 1;
        //     }
        //     objects.push({ mode: 'LINE_STRIP', start, count, color });
        // }
    }
    return { vertices, normals, objects };
}