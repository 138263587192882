import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    nbApiCalls: 0,
  },
  mutations: {
  },
  actions: {
    getIcon(context, { path, vue }) {
      const l = localStorage.getItem(`icon-${path}`);
      if (l) return new Promise( (res) => res(JSON.parse(l)) );
      return vue.$api.get('/assets/svg?path=' + path).then(j => {
        localStorage.setItem(`icon-${path}`, JSON.stringify(j.data));
        return j.data;
      })
    }
  },
  modules: {
  }
})
