

export const centerRadius2Circle = (center, radius, segments) => {
        
    segments = segments || 8;
    radius = radius / 180000;

    const poly = [];

    const delta = Math.PI * 2 / segments;
    for (let i=0; i<=segments; i++) {
        poly.push([
            center.lat + (radius * Math.sin(delta * i) * 0.804),
            center.lng + (radius * Math.cos(delta * i))
        ])
    }

    return poly;
}
