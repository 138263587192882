/**
 * Adds style switcher similar to Google Maps.
 * @param {Object} options
 * @param {Array} [options.styles] - Array of style objects:
 * @param {String} options.styles.label - Style label to display on switcher
 * @param {String} options.styles.styleName - [Style name from spec](https://docs.mapbox.com/mapbox-gl-js/style-spec/#root-name)
 * @param {String} options.styles.style - Style url
 */

import $$ from './helper.js';

class StyleSelectorControl {

  map = null;
  container = null;

  constructor(options) {
    this.styles = options.styles;
  }

  onAdd(map) {
    this.map = map;
    this.insertControls();
    return this.container;
  }

  insertControls() {
    const self = this;

    this.container = $$.container('mapboxgl-ctrl-styles');

    this.buttons = [];
    this.styles.forEach(s => {

      const button = $$.button(s.label, s.style.name === this.map.getStyle().name);

      button.addEventListener('click', () => {
        if (button.classList.contains('active')) return;
        self.map.setStyle(s.style);
        this.buttons.forEach(b => b.classList.remove('active'));
        button.classList.add('active');
      });

      self.buttons.push(button);
      self.container.appendChild(button);
    });
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

export default StyleSelectorControl;