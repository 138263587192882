import $$ from './helper.js';

class ShowTilesCoordinatesControl {

    map;
    container;

    onAdd(map) {
      this.map = map;

      this.container = $$.container('mapboxgl-ctrl-tiles-coordinates');

      const button = $$.button('X/Y');
      button.title = 'Toggle Tiles Coordinates';
      button.addEventListener('click', () => {
        this.map.showTileBoundaries = !this.map.showTileBoundaries;
      });

      this.container.appendChild(button);
      return this.container;
    }

    onRemove() {
        this.container.parentNode.removeChild(this.container);
        this.map = undefined;
    }
  }

export default ShowTilesCoordinatesControl;  