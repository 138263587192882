class ExternalEditorsControl {
    onAdd(map) {
      this.map = map;
      const self = this;

      this.container = document.createElement('div');
      this.container.classList.add('mapboxgl-ctrl');
      this.container.classList.add('mapboxgl-ctrl-group');
      this.container.classList.add('mapboxgl-ctrl-external-editors');

      const josm = document.createElement('button');
      josm.textContent = 'josm';
      josm.title = 'Edit in JOSM';
      josm.addEventListener('click', function () {
        const [[left, bottom], [right, top]] = self.map.getBounds().toArray();
        let link = `http://127.0.0.1:8111/load_and_zoom?left=${left}&right=${right}&top=${top}&bottom=${bottom}`;
        fetch(link).catch(err => 
          alert(`${err}\n\nMake sure JOSM is running and that you have enabled the remote feature.`)
        );
      });

      const iD = document.createElement('button');
      iD.textContent = 'iD';
      iD.title = 'Edit in iD';
      iD.addEventListener('click', function () {
        const [lng, lat] = self.map.getCenter().toArray();
        const zoom = self.map.getZoom();
        const a = document.createElement('a');
        a.href = `https://www.openstreetmap.org/edit#map=${zoom}/${lat}/${lng}`;
        a.target = '_blank';
        a.click();
      });
      
      self.container.appendChild(josm);
      self.container.appendChild(iD);
      return this.container;
    }
  
    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this.map = undefined;
    }
  }
  
export default ExternalEditorsControl;  