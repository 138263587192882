<template>
  <div id="app">
    <div v-if="updateExists && !hideUpdate" id="updateApp">
      An update is available
      <button @click="refreshApp"> Update </button>
      <br>
      <span class="action" @click="hideUpdate = true"> Not now </span>
    </div>
    <MainMenu />
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<script>
import MainMenu from './components/MainMenu.vue'
import update from './mixins/update'

export default {
  components: { MainMenu },
  name: 'App',
  mixins: [update],
  data() {
    return {
      hideUpdate: false
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";

#updateApp {
  position: fixed;
  background: white;
  padding: 7px;
  top: 20px;
  width: 200px;
  z-index: 100;
  left: 50%;
  text-align: center;
  margin-left: -100px;
  box-shadow: 0 0 11px;
  border-radius: 6px;

  button {
    background: #eaeaea;
    padding: 8px 16px;
    border: 1px solid #aaa;
    margin: 9px;
  }
}

</style>
