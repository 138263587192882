<template>
  <nav id="top-nav">
    <div id="nbApiCalls"><div v-for="i in $store.state.nbApiCalls" :key="i"></div></div>
    <ul>
      <li><router-link id="logo" to="/about">WHERE.tn</router-link></li>
      <li><router-link to="/">Map</router-link></li>
      <li><router-link to="/osm-stats">Stats</router-link></li>
      <li class="hide-phone"><router-link to="/style-editor">Style Editor</router-link></li>
      <!-- <li><router-link to="/resources">Liens Utiles</router-link></li> -->
      <!-- <li><router-link to="/glossary">Glossaire</router-link></li> -->
      <li>
      </li>
      <!-- <li><router-link to="/about">About</router-link></li> -->
      <!-- <li><a href="#" title="Soon ...">Login</a></li> -->
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MainMenu',
}
</script>

<style lang="scss" scoped>

#nbApiCalls {
  position: fixed;
  top: 2px;
  left: 2px;

  & > div {
    float: left;
    width: 4px;
    height: 4px;
    background: red;
    margin-right: 2px;
  }
}

#top-nav {
  background: #1c2028;
  height: var(--mainMenuHeight);
  text-transform: uppercase;
  font-size: 13px;
  user-select: none;

  & > ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    height: 100%;
    align-items: center;
    
    & > li {
      display: flex;
      
      &:last-child {
        margin-left: auto;
      }

      & > a {
        font-weight: bold;
        color: #bbb;
        padding: 15px;
        text-align: center;

        @media only screen and (max-width: 500px) {
          padding: 10px;
        }

        &:hover {
          background-color: rgb(60, 68, 85);
        }

        &.router-link-exact-active {
          color: #fff;
        }
      }
    }
  }
}

#logo {
  background-image: linear-gradient(90deg, #F79533 0%, #F37055 15%, #EF4E7B 30%, #A166AB 44%, #5073B8 58%, #1098AD 72%, #07B39B 86%, #6DBA82 100%);
  background-size: cover;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 26px;
  margin-left: 50px;
  margin-right: 50px;
  text-transform: none;
}
@media only screen and (max-width: 640px) {
  #logo {
    margin-left: 0;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 440px) {
  #logo {
    margin-right: 0;
  }
}

</style>