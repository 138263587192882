/**
 * 
 * @todo Fix circle which looks like an ellipse
 */

import { centerRadius2Circle } from "./functions";
import v3 from "../v3";


export const cylinder = function ({ center, radius, height, segments, color, wireframe }) {
    let vertices = [];
    let normals = [];
    const objects = [];
    let index = 0;
    if (!center.height) center.height = 0;

    segments = segments || 8;
    const circle = centerRadius2Circle(center, radius, segments);

    // BOTTOM
    if (!wireframe) {
        vertices = vertices.concat( [center.lat, center.lng, center.height] );
        normals.push(new v3(0, 0, -1));
    }
    circle.forEach(([lat, lng]) => {
        vertices = vertices.concat( [lat, lng, center.height] )
        normals.push(new v3(0, 0, -1));
    });

    objects.push({
        mode: wireframe ? 'LINE_STRIP' : 'TRIANGLE_FAN',
        start: index,
        count: (wireframe ? 0 : 1) + circle.length,
        color: color || [0, 0, 0, 1]
    });

    index = vertices.length / 3;

    // top
    if (!wireframe) {
        vertices = vertices.concat([center.lat, center.lng, center.height + height]);
        normals.push(new v3(0, 0, 1));
    }
    circle.forEach(([lat, lng]) => {
        vertices = vertices.concat( [lat, lng, center.height + height] )
        normals.push(new v3(0, 0, 1));
    });
    objects.push({
        mode: wireframe ? 'LINE_STRIP' : 'TRIANGLE_FAN',
        start: index,
        count: (wireframe ? 0 : 1) + circle.length,
        color: color || [0, 0, 0, 1]
    });


    // side
    index = vertices.length / 3;
    circle.forEach(([lat, lng]) => {
        vertices = vertices.concat(
            [lat, lng, center.height],
            [lat, lng, center.height + height]
        );
        normals.push(new v3(lat - center.lat, lng-center.lng, 0));
        normals.push(new v3(lat - center.lat, lng-center.lng, 0));

    });
    objects.push({
        mode: wireframe ? 'LINES' : 'TRIANGLE_STRIP',
        start: index,
        count: 2 * circle.length,
        color: color || [0, 0, 0, 1]
    });

    return {
        vertices,
        normals,
        objects
    }
}


export const circle = function ({ center, radius, segments, color, wireframe }) {
    let vertices = [];
    const objects = [];
    if (!center.height) center.height = 0;

    // center
    if (!wireframe) vertices = vertices.concat(
        [center.lat, center.lng, center.height]
    );

    // outer
    centerRadius2Circle(center, radius, segments)
        .forEach(([lat, lng]) => {
            vertices = vertices.concat(
                [lat, lng, center.height]
            );
        });

    objects.push({
        mode: wireframe ? 'LINE_STRIP' : 'TRIANGLE_FAN',
        start: 0,
        count: (wireframe ? 1 : 2) + (segments),
        color: color || [0, 0, 0, 1]
    });

    return {
        vertices,
        objects
    }
}

