
export default {
    api: {
        port: 3220,
        url: 'https://api.maps.selimachour.me'
    },
    mvt: {
        port: 3221,
        url: 'https://tiles.maps.selimachour.me/mvt/{z}/{x}/{y}.mvt',
        wireframe: 'https://tiles.maps.selimachour.me/mvt/wireframe/{z}/{x}/{y}.mvt',
        // glyphsUrl: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
        glyphsUrl: "https://tiles.maps.selimachour.me/mvt/fonts/mapbox/{fontstack}/{range}.pbf",
    },
    db: {
        main: {
            host: 'localhost',
            database: 'selim_where',
            dialect: 'postgres',
            user: 'selim',
            password: 'selim',
            port: 5432,
        }
    }
};


