import CONFIG from "../../../common/config";

const RealWidth = [
  "interpolate", 
  ["exponential", 2], 
  ["zoom"],
   6, ["*", ["get", "my_width"], ["^", 2, -4]], 
  24, ["*", ["get", "my_width"], ["^", 2,  8]]
];

const style = {
  label: 'Streets',
  tags: [ 'Where.tn', 'osm', 'roads' ],
  style: {
    version: 8,
    name: 'Streets',
    glyphs: CONFIG.mvt.glyphsUrl,
    sources: {
      "where": {
        "type": "vector",
        "tiles": [
          CONFIG.mvt.url
        ]
      },
      MapboxTerrain: {
        type: 'raster-dem',
        tiles: [
            'https://tiles.where.tn/Mapbox/mapbox-terrain-dem-v1/{z}/{x}/{y}.webp',
        ],
        maxzoom: 14,
        tileSize: 256,
      }
    },
    layers: [
      {
        "id": "land",
        "type": "background",
        "metadata": {},
        "layout": {},
        "paint": {
          "background-color": [
            "interpolate", ["linear"], ["zoom"],
            11,
            "#dfe1e5",
            13,
            "#e5d6ce"
          ]
        }
      },
      {
        id: 'MapboxTerrain',
        type: 'hillshade',
        source: 'MapboxTerrain',
        "source-layer": "hillshade",
        minzoom: 0,
        maxzoom: 22,
        "layout": {
          visibility: "none",
        },
        paint: {
          "hillshade-accent-color": "hsla(0, 0%, 0%, 0.23)",
          "hillshade-exaggeration": 0.2,
          "hillshade-illumination-direction": 0,
          "hillshade-highlight-color": "hsl(0, 3%, 60%)"
        },  
      },
      {
        "id": "oceans-low",
        "type": "fill",
        "source": "where",
        "source-layer": "oceans",
        "minzoom": 0,
        "maxzoom": 24,
        "filter": [
          "all"
        ],
        "layout": {},
        "paint": {
          "fill-color": "#75cff0"
        }
      },
      {
        "id": "landcover",
        "type": "fill",
        "metadata": {},
        "source": "where",
        "source-layer": "landcover",
        "maxzoom": 7,
        "layout": {},
        "paint": {
          "fill-color": "#e9d5cf",
          "fill-opacity": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            2,
            0.3,
            7,
            0
          ],
          "fill-antialias": false
        }
      },
      {
        "id": "national-park",
        "type": "fill",
        "metadata": {},
        "source": "where",
        "source-layer": "landuse_overlay",
        "minzoom": 5,
        "filter": [
          "==",
          ["get", "class"],
          "national_park"
        ],
        "layout": {},
        "paint": {
          "fill-color": "#b6e59e",
          "fill-opacity": [
            "interpolate", ["linear"], ["zoom"],
            5,
            0,
            6,
            0.5,
            10,
            0.5
          ]
        }
      },
      {
        "id": "landuse",
        "type": "fill",
        "metadata": {},
        "source": "where",
        "source-layer": "landuse",
        "minzoom": 5,
        "filter": ["all"],
        "layout": {},
        "paint": {
          "fill-color": [
            "case",
            ["==", ["get", "leisure"], "golf_course"], "#b6e59e",
            ["==", ["get", "leisure"], "park"], "#81e77a",
            ["==", ["get", "leisure"], "garden"], "#b6e59e",
            ["==", ["get", "landuse"], "meadow"], "#b6e59e",
            ["==", ["get", "leisure"], "pitch"], ["case",
              ["==", ["get", "sport"], "soccer"], "#b6e59e",
              ["==", ["get", "sport"], "skateboard"], "#eeeeee",
              ["==", ["get", "sport"], "tennis"], "#f79c81",
              "hsla(0, 0%, 0%, 0)"
            ],
            ["==", ["get", "landuse"], "brownfield"], "#eec47e",
            ["==", ["get", "landuse"], "greenfield"], "#b6c689",
            ["==", ["get", "landuse"], "forest"], "#89c697",
            ["==", ["get", "natural"], "wood"], "#89c697",
            ["==", ["get", "natural"], "airport"], "#dbdeeb",
            ["==", ["get", "landuse"], "cemetery"], "#d8e0bd",
            ["==", ["get", "natural"], "glacier"], "#e0f3fa",
            ["==", ["get", "natural"], "hospital"], "#f5d1dd",
            ["==", ["get", "natural"], "pitch"], "#aae08f",
            ["==", ["get", "natural"], "sand"], "#ededcf",
            ["==", ["get", "natural"], "beach"], "#faeb8e",
            ["==", ["get", "natural"], "school"], "#f0e7bc",
            "hsla(0, 0%, 0%, 0)"
          ]
        }
      },
      {
        "id": "pitch-outline",
        "type": "line",
        "metadata": {},
        "source": "where",
        "source-layer": "landuse",
        "minzoom": 15,
        "filter": [
          "==",
          ["get", "leisure"],
          "pitch"
        ],
        "layout": {},
        "paint": {
          "line-color": "#e2edbf"
        }
      },
      {
        "id": "water-shadow",
        "type": "fill",
        "metadata": {},
        "source": "where",
        "source-layer": "water",
        "layout": {},
        "paint": {
          "fill-color": "#6ea5f2",
          "fill-translate": [
            "interpolate",
            [
              "exponential",
              1.2
            ],
            [
              "zoom"
            ],
            7,
            ["literal", [0, 0]],
            16,
            ["literal", [-1, -1]]
          ],
          "fill-translate-anchor": "viewport"
        }
      },
      {
        "id": "waterway",
        "type": "line",
        "source": "where",
        "source-layer": "waterway",
        "minzoom": 8,
        "layout": {
          "line-cap": ["step", ["zoom"], "butt", 11, "round"],
          "line-join": "round"
        },
        "paint": {
          "line-color": "#8dcbf7",
          "line-width": [
            "interpolate", ["exponential", 1.3], ["zoom"],
            9,
            [
              "match",
              ["get", "class"],
              ["canal", "river"],
              0.1,
              0
            ],
            20,
            [
              "match",
              ["get", "class"],
              ["canal", "river"],
              8,
              3
            ]
          ],
          "line-opacity": [
            "interpolate", ["linear"], ["zoom"],
            8,
            0,
            8.5,
            1
          ]
        }
      },
      {
        "id": "water",
        "type": "fill",
        "source": "where",
        "source-layer": "water",
        "paint": {
          "fill-color": "#75cff0"
        }
      },
      {
        "id": "aeroway-polygon",
        "type": "fill",
        "source": "where",
        "source-layer": "aeroway",
        "minzoom": 11,
        "filter": [
          "all",
          ["==", ["geometry-type"], "Polygon"],
          [
            "match",
            ["get", "type"],
            ["runway", "taxiway", "helipad"],
            true,
            false
          ]
        ],
        "paint": {
          "fill-color": [
            "interpolate", ["linear"], ["zoom"],
            15, "#c7cadc",
            16, "#c7cce5"
          ],
          "fill-opacity": [
            "interpolate", ["linear"], ["zoom"],
            11, 0,
            11.5, 1
          ]
        }
      },
      {
        "id": "aeroway-line",
        "type": "line",
        "source": "where",
        "source-layer": "aeroway",
        "minzoom": 9,
        "filter": [
          "==",
          [
            "geometry-type"
          ],
          "LineString"
        ],
        "layout": {
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            15,
            "#c7cadc",
            16,
            "#c7cce5"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            9,
            [
              "match",
              ["get", "type"],
              "runway",
              1,
              "taxiway",
              0.5,
              0.5
            ],
            18,
            [
              "match",
              ["get", "type"],
              "runway",
              80,
              "taxiway",
              20,
              20
            ]
          ]
        }
      },
      {
        "id": "building",
        "type": "fill",
        "metadata": {},
        "source": "where",
        "source-layer": "building",
        "minzoom": 15,
        "filter": [
          "all",
          ["!=", ["get", "type"], "building:part"]
        ],
        "layout": {
        },
        "paint": {
          "fill-color": "#e3e3e3",
          "fill-opacity": [
           "interpolate",
           [
            "linear"
           ],
           [
            "zoom"
           ],
           15,
           0.3,
           18,
           1
          ],
          "fill-outline-color": "#9e9e9e"        }
      },
      {
        "id": "tunnel-street-minor-low",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link"
              ],
              true,
              false
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              2,
              "track",
              1,
              0.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              18,
              12
            ]
          ],
          "line-color": "#ffffff",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            1,
            14,
            0
          ]
        }
      },
      {
        "id": "tunnel-street-minor-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link"
              ],
              true,
              false
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.75,
            20,
            2
          ],
          "line-color": "#b3b7cb",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              2,
              "track",
              1,
              0.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              18,
              12
            ]
          ],
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ],
          "line-dasharray": [
            3,
            3
          ]
        }
      },
      {
        "id": "tunnel-prim-sec-ter-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "primary",
              "secondary",
              "tertiary"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            [
              "match",
              ["get", "highway"],
              "primary",
              1,
              [
                "secondary",
                "tertiary"
              ],
              0.75,
              0.75
            ],
            18,
            2
          ],
          "line-color": "#b3b7cb",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            [
              "match",
              ["get", "highway"],
              "primary",
              0.75,
              [
                "secondary",
                "tertiary"
              ],
              0.1,
              0.1
            ],
            18,
            [
              "match",
              ["get", "highway"],
              "primary",
              32,
              [
                "secondary",
                "tertiary"
              ],
              26,
              26
            ]
          ],
          "line-dasharray": [
            3,
            3
          ]
        }
      },
      {
        "id": "tunnel-major-link-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.75,
            20,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ],
          "line-dasharray": [
            3,
            3
          ]
        }
      },
      {
        "id": "tunnel-motorway-trunk-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            1,
            18,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            0.75,
            18,
            32
          ],
          "line-dasharray": [
            3,
            3
          ]
        }
      },
      {
        "id": "tunnel-construction",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "==",
            ["get", "highway"],
            "construction"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {},
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            2,
            18,
            18
          ],
          "line-color": "#d6d9e6",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [0.4, 0.8]],
            15,
            ["literal", [0.3, 0.6]],
            16,
            ["literal", [0.2, 0.3]],
            17,
            ["literal", [0.2, 0.25]],
            18,
            ["literal", [0.15, 0.15]]
          ]
        }
      },
      {
        "id": "tunnel-path",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "==",
            ["get", "highway"],
            "path"
          ],
          [
            "!=",
            ["get", "highway"],
            "steps"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            1,
            18,
            4
          ],
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [1, 0.5]]
          ],
          "line-color": "#f6f3ef"
        }
      },
      {
        "id": "tunnel-steps",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "==",
            ["get", "highway"],
            "steps"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            1,
            16,
            1.6,
            18,
            6
          ],
          "line-color": "#f6f3ef",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [0.3, 0.3]]
          ]
        }
      },
      {
        "id": "tunnel-major-link",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway_link",
            "#ffbf8f",
            "trunk_link",
            "#f2de9c",
            "#f2de9c"
          ]
        }
      },
      {
        "id": "tunnel-pedestrian",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "==",
            ["get", "highway"],
            "pedestrian"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            18,
            12
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.5, 0.4]],
            16,
            ["literal", [1, 0.2]]
          ]
        }
      },
      {
        "id": "tunnel-street-minor",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link"
              ],
              true,
              false
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              2,
              "track",
              1,
              0.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              18,
              12
            ]
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "living_street",
            "#f0eeeb",
            "#ffffff"
          ],
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "tunnel-prim-sec-ter",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "primary",
              "secondary",
              "tertiary"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            [
              "match",
              ["get", "highway"],
              "primary",
              0.75,
              [
                "secondary",
                "tertiary"
              ],
              0.1,
              0.1
            ],
            18,
            [
              "match",
              ["get", "highway"],
              "primary",
              32,
              [
                "secondary",
                "tertiary"
              ],
              26,
              26
            ]
          ],
          "line-color": "#ffffff"
        }
      },
      {
        "id": "tunnel-oneway-arrow-blue",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 15,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "==",
            ["get", "oneway"],
            "true"
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "residential",
                "living_street",
                "tertiary"
              ],
              true,
              false
            ],
            16,
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "path",
                "pedestrian",
                "service",
                "track"
              ],
              true,
              false
            ]
          ]
        ],
        "layout": {
          "symbol-placement": "line",
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            "oneway-small",
            17,
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street"
              ],
              "oneway-large",
              "oneway-small"
            ],
            18,
            "oneway-large"
          ],
          "symbol-spacing": 200,
          "icon-rotation-alignment": "map"
        },
        "paint": {}
      },
      {
        "id": "tunnel-motorway-trunk",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            0.75,
            18,
            32
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway",
            "#ffbf8f",
            "trunk",
            "#f2de9c",
            "#f2de9c"
          ]
        }
      },
      {
        "id": "tunnel-oneway-arrow-white",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 16,
        "filter": [
          "all",
          ["has", "tunnel"],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "motorway_link",
              "trunk",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "==",
            ["get", "oneway"],
            "true"
          ]
        ],
        "layout": {
          "symbol-placement": "line",
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            "oneway-white-small",
            17,
            "oneway-white-large"
          ],
          "symbol-spacing": 200
        },
        "paint": {}
      },
      {
        "id": "ferry",
        "type": "line",
        "metadata": {},
        "source": "where",
        "source-layer": "road",
        "minzoom": 8,
        "filter": [
          "==",
          ["get", "type"],
          "ferry"
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            15,
            "#5cace6",
            17,
            "#5c73e6"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            20,
            1
          ],
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            13,
            ["literal", [12, 4]]
          ]
        }
      },
      {
        "id": "ferry-auto",
        "type": "line",
        "metadata": {},
        "source": "where",
        "source-layer": "road",
        "filter": [
          "==",
          ["get", "type"],
          "ferry_auto"
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            15,
            "#5cace6",
            17,
            "#5c73e6"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            20,
            1
          ]
        }
      },
      {
        "id": "road-path-bg",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            ["path", "footway"],
            true, false
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "!",
              [
                "match",
                ["get", "type"],
                [
                  "steps",
                  "sidewalk",
                  "crossing"
                ],
                true,
                false
              ]
            ],
            16,
            [
              "!=",
              ["get", "type"],
              "steps"
            ]
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            2,
            18,
            7
          ],
          "line-color": "#c9ccd9",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            0.75
          ]
        }
      },
      {
        "id": "road-steps-bg",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          ["==", ["get", "highway"], "steps"]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            2,
            17,
            4.6,
            18,
            7
          ],
          "line-color": "#c9ccd9",
          "line-opacity": 0.75
        }
      },
      {
        "id": "turning-feature-outline",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 15,
        "filter": [
          "all",
          [
            "==",
            [
              "geometry-type"
            ],
            "Point"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "turning_circle",
              "turning_loop"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "icon-image": "turning-circle-outline",
          "icon-size": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.122,
            18,
            0.969,
            20,
            1
          ],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-padding": 0,
          "icon-rotation-alignment": "map"
        },
        "paint": {}
      },
      {
        "id": "road-pedestrian-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "pedestrian"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            2,
            18,
            14.5
          ],
          "line-color": "#d6d9e6",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "road-minor-low",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "step",
            [
              "zoom"
            ],
            [
              "==",
              ["get", "highway"],
              "track"
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "track",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": RealWidth,
          "line-color": "#ffffff",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            1,
            14,
            0
          ]
        }
      },
      {
        "id": "road-minor-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "step",
            [
              "zoom"
            ],
            [
              "==",
              ["get", "highway"],
              "track"
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "track",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": RealWidth,
          "line-color": "#d6d9e6",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            [
              "match",
              ["get", "highway"],
              "track",
              1,
              0.5
            ],
            18,
            12
          ],
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "road-street-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 11,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "residential",
              "living_street",
              "primary_link"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": 1,
          "line-color": "#b9c752",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ],
          "line-opacity": [
             "interpolate",
     [
      "exponential",
      1.5
     ],
     [
      "zoom"
     ],
     13,
     0,
     16,
     1
          ]
        }
      },
      {
        "id": "road-secondary-tertiary-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "secondary",
              "tertiary"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            0.75,
            18,
            2
          ],
          "line-color": "#d6d9e6",
          "line-gap-width": RealWidth,
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            10,
            1
          ]
        }
      },
      {
        "id": "road-primary-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "primary"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            1,
            18,
            2
          ],
          "line-color": "#d6d9e6",
          "line-gap-width": RealWidth,
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            10,
            1
          ]
        }
      },
      {
        "id": "road-major-link-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 10,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.75,
            20,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": RealWidth,
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            11,
            1
          ]
        }
      },
    {
   "id": "road-motorway-trunk-case",
   "type": "line",
   "source": "where",
   "source-layer": "road",
   "filter": [
    "all",
    [
     "match",
     [
      "get",
      "highway"
     ],
     [
      "motorway",
      "trunk"
     ],
     true,
     false
    ]
   ],
   "layout": {
    "line-cap": "round",
    "line-join": "round",
    "visibility": "visible"
   },
   "paint": {
    "line-width": 1,
    "line-color": "#b9c752",
    "line-gap-width": RealWidth,
    "line-opacity": [
     "interpolate",
     [
      "exponential",
      1.5
     ],
     [
      "zoom"
     ],
     13,
     0,
     16,
     1
    ]
   }
  },
      {
        "id": "road-construction",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "construction"
          ]
        ],
        "layout": {},
        "paint": {
          "line-width": RealWidth,
          "line-color": "#d6d9e6",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [0.4, 0.8]],
            15,
            ["literal", [0.3, 0.6]],
            16,
            ["literal", [0.2, 0.3]],
            17,
            ["literal", [0.2, 0.25]],
            18,
            ["literal", [0.15, 0.15]]
          ]
        }
      },
      {
        "id": "road-path",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            ["path", "footway"],
            true, false
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "!",
              [
                "match",
                ["get", "type"],
                [
                  "steps",
                  "sidewalk",
                  "crossing"
                ],
                true,
                false
              ]
            ],
            16,
            [
              "!=",
              ["get", "type"],
              "steps"
            ]
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            13,
            0.5,
            14,
            1,
            15,
            1,
            18,
            4
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [1, 0.5]]
          ]
        }
      },
      {
        "id": "road-steps",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": ["all", ["==", ["get", "highway"], "steps"]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            1,
            16,
            1.6,
            18,
            6
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [0.3, 0.3]]
          ]
        }
      },
      {
        "id": "road-major-link",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 10,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway_link",
            "#ffa35c",
            "trunk_link",
            "#f2d163",
            "#f2d163"
          ]
        }
      },
      {
        "id": "road-pedestrian",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "pedestrian"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            18,
            12
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step", [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.5, 0.4]],
            16,
            ["literal", [1, 0.2]]
          ]
        }
      },
      {
        "id": "road-pedestrian-polygon-fill",
        "type": "fill",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "==",
            [
              "geometry-type"
            ],
            "Polygon"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "path",
              "pedestrian"
            ],
            true,
            false
          ]
        ],
        "layout": {},
        "paint": {
          "fill-color": [
            "interpolate", ["linear"], ["zoom"],
            16,
            "#edeef2",
            16.25,
            "#f7f8fc"
          ],
          "fill-outline-color": "#d8dbe8"
        }
      },
      {
        "id": "road-pedestrian-polygon-pattern",
        "type": "fill",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "==",
            [
              "geometry-type"
            ],
            "Polygon"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "path",
              "pedestrian"
            ],
            true,
            false
          ]
        ],
        "layout": {},
        "paint": {
          "fill-color": "#ffffff",
          "fill-outline-color": "#d8d4cf",
          "fill-pattern": "pedestrian-polygon",
          "fill-opacity": [
            "interpolate", ["linear"], ["zoom"],
            16,
            0,
            16.25,
            1
          ]
        }
      },
      {
        "id": "road-polygon",
        "type": "fill",
        "source": "where",
        "source-layer": "road",
        "minzoom": 12,
        "filter": [
          "all",
          [
            "==",
            [
              "geometry-type"
            ],
            "Polygon"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "primary",
              "secondary",
              "tertiary",
              "primary_link",
              "secondary_link",
              "tertiary_link",
              "residential",
              "living_street",
              "track",
              "service"
            ],
            true,
            false
          ]
        ],
        "layout": {},
        "paint": {
          "fill-color": "#ffffff",
          "fill-outline-color": "#d6d9e6"
        }
      },
      {
        "id": "road-minor",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "step",
            [
              "zoom"
            ],
            [
              "==",
              ["get", "highway"],
              "track"
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "track",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": RealWidth,
          "line-color": "#ffffff",
          "line-opacity": 0.8,
          "line-dasharray": [1, 2]
        }
      },
      {
        "id": "road-street",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 11,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "residential",
              "living_street",
              "primary_link",
              "unclassified"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-opacity": [
             "interpolate",
             [
              "exponential",
              1.5
             ],
             [
              "zoom"
             ],
             11,
             0.5,
             15,
             1
          ],
          "line-width": RealWidth,
          "line-color": [
            "match",
            ["get", "highway"],
            "living_street",
            "#f0eeeb",
            "#ffffff"
          ]
        }
      },
      {
        "id": "road-secondary-tertiary",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "secondary",
              "tertiary"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": RealWidth,
          "line-color": "#ffffff"
        },
      },
      {
        "id": "road-primary",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "primary"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": RealWidth,
          "line-color": "#ffffff"
        }
      },
      /*
      {
        "id": "road-oneway-arrow-blue",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 15,
        "filter": [
          "all",
          [
            "==",
            ["get", "oneway"],
            "yes"
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street"
              ],
              true,
              false
            ],
            16,
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "path",
                "pedestrian",
                "service",
                "track"
              ],
              true,
              false
            ]
          ]
        ],
        "layout": {
          "symbol-placement": "line",
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            "oneway-small",
            17,
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street"
              ],
              "oneway-large",
              "oneway-small"
            ],
            18,
            "oneway-large"
          ],
          "symbol-spacing": 200,
          "icon-rotation-alignment": "map"
        },
        "paint": {}
      },
      */
      {
   "id": "road-motorway-trunk",
   "type": "line",
   "source": "where",
   "source-layer": "road",
   "filter": [
    "all",
    [
     "match",
     [
      "get",
      "highway"
     ],
     [
      "motorway",
      "trunk"
     ],
     true,
     false
    ]
   ],
   "layout": {
    "line-cap": "round",
    "line-join": "round",
    "visibility": "visible"
   },
   "paint": {
    "line-width": RealWidth,
    "line-color": [
     "step",
     [
      "zoom"
     ],
     [
      "match",
      [
       "get",
       "highway"
      ],
      "motorway",
      "#f2934a",
      "trunk",
      "#ffffff",
      "#ffffff"
     ],
     6,
     [
      "match",
      [
       "get",
       "highway"
      ],
      "motorway",
      "#f2934a",
      "trunk",
      "#ebc567",
      "#ebc567"
     ],
     9,
     [
      "match",
      [
       "get",
       "highway"
      ],
      "motorway",
      "#ff635c",
      "trunk",
      "#f2e153",
      "#f2d163"
     ]
    ]
   }
  },
      {
        "id": "road-rail",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "major_rail",
              "minor_rail"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            13,
            "#d9d6c9",
            16,
            "#b6b8c3"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            20,
            1
          ]
        }
      },
      {
        "id": "road-rail-tracks",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "major_rail",
              "minor_rail"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            13,
            "#d9d6c9",
            16,
            "#b6b8c3"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            4,
            20,
            8
          ],
          "line-dasharray": [
            0.1,
            15
          ],
          "line-opacity": [
            "interpolate", ["linear"], ["zoom"],
            13.75,
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "level-crossing",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 16,
        "filter": [
          "==",
          ["get", "highway"],
          "level_crossing"
        ],
        "layout": {
          "icon-image": "level-crossing",
          "icon-allow-overlap": true
        },
        "paint": {}
      },
      {
        "id": "road-oneway-arrow-white",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 16,
        "filter": [
          "all",
          [
            "==",
            ["get", "oneway"],
            "yes"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk",
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "symbol-placement": "line",
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            "oneway-white-small",
            17,
            "oneway-white-large"
          ],
          "symbol-spacing": 200
        },
        "paint": {}
      },
      {
        "id": "turning-feature",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 15,
        "filter": [
          "all",
          [
            "==",
            [
              "geometry-type"
            ],
            "Point"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "turning_circle",
              "turning_loop"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "icon-image": "turning-circle",
          "icon-size": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.095,
            18,
            1
          ],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-padding": 0,
          "icon-rotation-alignment": "map"
        },
        "paint": {}
      },
      {
        "id": "golf-hole-line",
        "type": "line",
        "metadata": {},
        "source": "where",
        "source-layer": "road",
        "minzoom": 16,
        "filter": [
          "==",
          ["get", "highway"],
          "golf"
        ],
        "layout": {},
        "paint": {
          "line-color": "#83c662"
        }
      },
      {
        "id": "bridge-path-bg",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            ["get", "highway"],
            "path"
          ],
          [
            "!=",
            ["get", "highway"],
            "steps"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            2,
            18,
            7
          ],
          "line-color": "#c9ccd9",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "bridge-steps-bg",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "steps"
          ],
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            2,
            17,
            4.6,
            18,
            7
          ],
          "line-color": "#c9ccd9",
          "line-opacity": 0.75
        }
      },
      {
        "id": "bridge-pedestrian-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            ["get", "highway"],
            "pedestrian"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            2,
            18,
            14.5
          ],
          "line-color": "#d6d9e6",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "bridge-street-minor-low",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link"
              ],
              true,
              false
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              2,
              "track",
              1,
              0.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              18,
              12
            ]
          ],
          "line-color": "#ffffff",
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            1,
            14,
            0
          ]
        }
      },
      {
        "id": "bridge-street-minor-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link"
              ],
              true,
              false
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.75,
            20,
            2
          ],
          "line-color": "#d6d9e6",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              2,
              "track",
              1,
              0.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              18,
              12
            ]
          ],
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "bridge-prim-sec-ter-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "primary",
              "secondary",
              "tertiary"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            [
              "match",
              ["get", "highway"],
              "primary",
              1,
              [
                "secondary",
                "tertiary"
              ],
              0.75,
              0.75
            ],
            18,
            2
          ],
          "line-color": "#d6d9e6",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            [
              "match",
              ["get", "highway"],
              "primary",
              0.75,
              [
                "secondary",
                "tertiary"
              ],
              0.1,
              0.1
            ],
            18,
            [
              "match",
              ["get", "highway"],
              "primary",
              32,
              [
                "secondary",
                "tertiary"
              ],
              26,
              26
            ]
          ],
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            10,
            1
          ]
        }
      },
      {
        "id": "bridge-major-link-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "<=",
            ["get", "layer"],
            1
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.75,
            20,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ]
        }
      },
      {
        "id": "bridge-motorway-trunk-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            true,
            false
          ],
          [
            "<=",
            ["get", "layer"],
            1
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            1,
            18,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            0.75,
            18,
            32
          ]
        }
      },
      {
        "id": "bridge-construction",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            ["get", "highway"],
            "construction"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {},
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            2,
            18,
            18
          ],
          "line-color": "#d6d9e6",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [0.4, 0.8]],
            15,
            ["literal", [0.3, 0.6]],
            16,
            ["literal", [0.2, 0.3]],
            17,
            ["literal", [0.2, 0.25]],
            18,
            ["literal", [0.15, 0.15]]
          ]
        }
      },
      {
        "id": "bridge-path",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            ["get", "highway"],
            "path"
          ],
          [
            "!=",
            ["get", "highway"],
            "steps"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            1,
            18,
            4
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [1, 0.5]]
          ]
        }
      },
      {
        "id": "bridge-steps",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 14,
        "filter": [
          "all",
          [
            "==",
            ["get", "highway"],
            "steps"
          ],
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            15,
            1,
            16,
            1.6,
            18,
            6
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.75, 1]],
            16,
            ["literal", [1, 0.75]],
            17,
            ["literal", [0.3, 0.3]]
          ]
        }
      },
      {
        "id": "bridge-major-link",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "<=",
            ["get", "layer"],
            1
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway_link",
            "#ffa35c",
            "trunk_link",
            "#f2d163",
            "#f2d163"
          ]
        }
      },
      {
        "id": "bridge-pedestrian",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            ["get", "highway"],
            "pedestrian"
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            18,
            12
          ],
          "line-color": "#ffffff",
          "line-dasharray": [
            "step",
            [
              "zoom"
            ],
            ["literal", [1, 0]],
            15,
            ["literal", [1.5, 0.4]],
            16,
            ["literal", [1, 0.2]]
          ]
        }
      },
      {
        "id": "bridge-street-minor",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link"
              ],
              true,
              false
            ],
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "track",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "service"
              ],
              true,
              false
            ]
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              2,
              "track",
              1,
              0.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              [
                "residential",
                "living_street",
                "primary_link"
              ],
              18,
              12
            ]
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "living_street",
            "#f0eeeb",
            "#ffffff"
          ],
          "line-opacity": [
            "step",
            [
              "zoom"
            ],
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "bridge-prim-sec-ter",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "primary",
              "secondary",
              "tertiary"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            [
              "match",
              ["get", "highway"],
              "primary",
              0.75,
              [
                "secondary",
                "tertiary"
              ],
              0.1,
              0.1
            ],
            18,
            [
              "match",
              ["get", "highway"],
              "primary",
              32,
              [
                "secondary",
                "tertiary"
              ],
              26,
              26
            ]
          ],
          "line-color": "#ffffff"
        }
      },
      {
        "id": "bridge-oneway-arrow-blue",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 15,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "==",
            ["get", "oneway"],
            "true"
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street"
              ],
              true,
              false
            ],
            16,
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street",
                "primary_link",
                "secondary_link",
                "tertiary_link",
                "path",
                "pedestrian",
                "track",
                "service"
              ],
              true,
              false
            ]
          ]
        ],
        "layout": {
          "symbol-placement": "line",
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            "oneway-small",
            17,
            [
              "match",
              ["get", "highway"],
              [
                "primary",
                "secondary",
                "tertiary",
                "residential",
                "living_street"
              ],
              "oneway-large",
              "oneway-small"
            ],
            18,
            "oneway-large"
          ],
          "symbol-spacing": 200,
          "icon-rotation-alignment": "map"
        },
        "paint": {}
      },
      {
        "id": "bridge-motorway-trunk",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            true,
            false
          ],
          [
            "<=",
            ["get", "layer"],
            1
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            0.75,
            18,
            32
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway",
            "#ffa35c",
            "trunk",
            "#f2d163",
            "#f2d163"
          ]
        }
      },
      {
        "id": "bridge-rail",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "major_rail",
              "minor_rail"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            13,
            "#d9d6c9",
            16,
            "#b6b8c3"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            20,
            1
          ]
        }
      },
      {
        "id": "bridge-rail-tracks",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "major_rail",
              "minor_rail"
            ],
            true,
            false
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": [
            "interpolate", ["linear"], ["zoom"],
            13,
            "#d9d6c9",
            16,
            "#b6b8c3"
          ],
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            4,
            20,
            8
          ],
          "line-dasharray": [
            0.1,
            15
          ],
          "line-opacity": [
            "interpolate", ["linear"], ["zoom"],
            13.75,
            0,
            14,
            1
          ]
        }
      },
      {
        "id": "bridge-major-link-2-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            ">=",
            ["get", "layer"],
            2
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.75,
            20,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ]
        }
      },
      {
        "id": "bridge-motorway-trunk-2-case",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            ">=",
            ["get", "layer"],
            2
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            10,
            1,
            18,
            2
          ],
          "line-color": "#ffffff",
          "line-gap-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            0.75,
            18,
            32
          ]
        }
      },
      {
        "id": "bridge-major-link-2",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            ">=",
            ["get", "layer"],
            2
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            12,
            0.5,
            14,
            2,
            18,
            18
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway_link",
            "#ffa35c",
            "trunk_link",
            "#f2d163",
            "#f2d163"
          ]
        }
      },
      {
        "id": "bridge-motorway-trunk-2",
        "type": "line",
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            ">=",
            ["get", "layer"],
            2
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "line-cap": "round",
          "line-join": "round"
        },
        "paint": {
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            5,
            0.75,
            18,
            32
          ],
          "line-color": [
            "match",
            ["get", "highway"],
            "motorway",
            "#ffa35c",
            "trunk",
            "#f2d163",
            "#f2d163"
          ]
        }
      },
      {
        "id": "bridge-oneway-arrow-white",
        "type": "symbol",
        "source": "where",
        "source-layer": "road",
        "minzoom": 16,
        "filter": [
          "all",
          [
            "==",
            ["get", "structure"],
            "bridge"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk",
              "motorway_link",
              "trunk_link"
            ],
            true,
            false
          ],
          [
            "==",
            ["get", "oneway"],
            "true"
          ]
        ],
        "layout": {
          "symbol-placement": "line",
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            "oneway-white-small",
            17,
            "oneway-white-large"
          ],
          "symbol-spacing": 200
        },
        "paint": {}
      },
      {
        "id": "aerialway",
        "type": "line",
        "metadata": {},
        "source": "where",
        "source-layer": "road",
        "minzoom": 13,
        "filter": [
          "==",
          ["get", "highway"],
          "aerialway"
        ],
        "layout": {
          "line-join": "round"
        },
        "paint": {
          "line-color": "#b6b8c3",
          "line-width": [
            "interpolate", ["exponential", 1.5], ["zoom"],
            14,
            0.5,
            20,
            1
          ]
        }
      },
      {
        "id": "admin-level_4",
        "type": "line",
        "source": "where",
        "source-layer": "admin_level",
        "minzoom": 4,
        "maxzoom": 17,
        "paint": {
          "line-opacity": 0.2,
          "line-color": "#787b8c",
          "line-width": ["interpolate", ["linear"], ["zoom"], 3, 0.2, 10, 1]
        }
      },
      {
        "id": "3d-buildings",
        "source": "where",
        "source-layer": "building",
        "filter": [
          "==",
          ["to-boolean", ["get", "my_has_building_part"]],
          false
        ],
        "type": "fill-extrusion",
        "minzoom": 15,
        layout: {
          "visibility": "none"
        },
        "paint": {
          "fill-extrusion-color": [
            "coalesce",
            ["get", "colour"],
            "#bbb"
          ],
          "fill-extrusion-height": [
            "interpolate", ["linear"], ["zoom"],
            15,
            0,
            15.05,
            ["get", "height"]
          ],
          "fill-extrusion-base": [
            "interpolate", ["linear"], ["zoom"],
            15,
            0,
            15.05,
            ["get", "min_height"]
          ],
          "fill-extrusion-opacity": 0.83
        }
      },
      {
        "id": "road-number-shield",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "road",
        "minzoom": 6,
        "filter": [
          "all",
          [
            "has",
            "reflen"
          ],
          [
            "<=",
            ["get", "reflen"],
            6
          ],
          [
            "step",
            [
              "zoom"
            ],
            [
              "==",
              [
                "geometry-type"
              ],
              "Point"
            ],
            11,
            [
              ">",
              ["get", "len"],
              5000
            ],
            12,
            [
              ">",
              ["get", "len"],
              2500
            ],
            13,
            [
              ">",
              ["get", "len"],
              1000
            ],
            14,
            true
          ]
        ],
        "layout": {
          "text-size": 9,
          "icon-image": [
            "concat",
            ["get", "shield"],
            "-",
            [
              "to-string",
              ["get", "reflen"]
            ]
          ],
          "icon-rotation-alignment": "viewport",
          "text-max-angle": 38,
          "symbol-spacing": [
            "interpolate", ["linear"], ["zoom"],
            11,
            150,
            14,
            200
          ],
          "text-font": [
            "DIN Offc Pro Bold",
            "Arial Unicode MS Bold"
          ],
          "symbol-placement": [
            "step",
            [
              "zoom"
            ],
            "point",
            11,
            "line"
          ],
          "text-rotation-alignment": "viewport",
          "text-field": ["get", "ref"],
          "text-letter-spacing": 0.05
        },
        "paint": {
          "text-color": [
            "match",
            ["get", "shield_text_color"],
            "white",
            "#ffffff",
            "black",
            "#121212",
            "yellow",
            "#ffe666",
            "orange",
            "#ffb580",
            "blue",
            "#2d3b80",
            "#ffffff"
          ]
        }
      },
      {
        "id": "road-exit-shield",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "motorway_junction",
        "minzoom": 14,
        "filter": [
          "all",
          [
            "has",
            "reflen"
          ],
          [
            "<=",
            ["get", "reflen"],
            9
          ]
        ],
        "layout": {
          "text-field": ["get", "ref"],
          "text-size": 9,
          "icon-image": [
            "concat",
            "motorway-exit-",
            [
              "to-string",
              ["get", "reflen"]
            ]
          ],
          "text-font": [
            "DIN Offc Pro Bold",
            "Arial Unicode MS Bold"
          ]
        },
        "paint": {
          "text-color": "#ffffff",
          "text-translate": [
            0,
            0
          ]
        }
      },
      {
        "id": "waterway-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "natural_label",
        "minzoom": 13,
        "filter": [
          "all",
          [
            "match",
            ["get", "highway"],
            [
              "canal",
              "river",
              "stream"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "text-font": [
            "DIN Offc Pro Italic",
            "Arial Unicode MS Regular"
          ],
          "text-max-angle": 30,
          "symbol-spacing": [
            "interpolate",
            [
              "linear",
              1
            ],
            [
              "zoom"
            ],
            15,
            250,
            17,
            400
          ],
          "text-size": [
            "interpolate", ["linear"], ["zoom"],
            13,
            12,
            18,
            16
          ],
          "symbol-placement": "line",
          "text-pitch-alignment": "viewport",
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ]
        },
        "paint": {
          "text-color": "#3a4ca6"
        }
      },
      {
        "id": "natural-line-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "natural_label",
        "minzoom": 4,
        "filter": [
          "all",
          [
            "match",
            ["get", "class"],
            [
              "glacier",
              "landform"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ],
          [
            "<=",
            ["get", "filterrank"],
            2
          ]
        ],
        "layout": {
          "text-size": [
            "step",
            [
              "zoom"
            ],
            [
              "step",
              ["get", "sizerank"],
              18,
              5,
              12
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              18,
              13,
              12
            ]
          ],
          "text-max-angle": 30,
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ],
          "text-font": [
            "DIN Offc Pro Medium",
            "Arial Unicode MS Regular"
          ],
          "symbol-placement": "line-center",
          "text-pitch-alignment": "viewport"
        },
        "paint": {
          "text-halo-width": 0.5,
          "text-halo-color": "#ffffff",
          "text-halo-blur": 0.5,
          "text-color": [
            "step",
            [
              "zoom"
            ],
            [
              "step",
              ["get", "sizerank"],
              "#816856",
              5,
              "#664f3d"
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              "#816856",
              13,
              "#664f3d"
            ]
          ]
        }
      },
      {
        "id": "natural-point",
        "source": "where",
        "source-layer": "natural_label",
        "minzoom": 14,
        "maxzoom": 24,
        'type': 'circle',
        'paint': {
          'circle-radius':  ["interpolate", ["exponential", 2], ["zoom"], 13, 1, 20, 8],
          'circle-color': '#22B422'
        }
      },
      {
        "id": "natural",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "natural_label",
        "minzoom": 4,
        "filter": ["all"],
        "layout": {
          "text-size": [
            "step",
            ["zoom"],
            [
              "step",
              ["get", "sizerank"],
              18,
              5,
              12
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              18,
              13,
              12
            ]
          ],
          "icon-image": [
            "step",
            [
              "zoom"
            ],
            [
              "concat",
              ["coalesce", ["get", "natural"], ["get", "shop"]],
              "-sm"
            ],
            15,
            [
              "concat",
              ["coalesce", ["get", "natural"], ["get", "shop"]],
              "-lg"
            ]
          ],
          "text-font": [
            "DIN Offc Pro Medium",
            "Arial Unicode MS Regular"
          ],
          "text-offset": [
            "step",
            [
              "zoom"
            ],
            [
              "step",
              ["get", "sizerank"],
              ["literal", [0, 0]],
              5,
              ["literal", [0, 0.75]]
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              ["literal", [0, 0]],
              13,
              ["literal", [0, 0.75]]
            ]
          ],
          "text-anchor": [
            "step",
            [
              "zoom"
            ],
            [
              "step",
              ["get", "sizerank"],
              "center",
              5,
              "top"
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              "center",
              13,
              "top"
            ]
          ],
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ]
        },
        "paint": {
          "icon-opacity": [
            "step",
            [
              "zoom"
            ],
            [
              "step",
              ["get", "sizerank"],
              0,
              5,
              1
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              0,
              13,
              1
            ]
          ],
          "text-halo-color": "#ffffff",
          "text-halo-width": 0.5,
          "text-halo-blur": 0.5,
          "text-color": [
            "step",
            [
              "zoom"
            ],
            [
              "step",
              ["get", "sizerank"],
              "#816856",
              5,
              "#664f3d"
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              "#816856",
              13,
              "#664f3d"
            ]
          ]
        }
      },
      {
        "id": "water-line-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "natural_label",
        "filter": [
          "all",
          [
            "match",
            ["get", "class"],
            [
              "bay",
              "ocean",
              "reservoir",
              "sea",
              "water"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "LineString"
          ]
        ],
        "layout": {
          "text-size": [
            "interpolate", ["linear"], ["zoom"],
            7,
            [
              "step",
              ["get", "sizerank"],
              24,
              6,
              18,
              12,
              12
            ],
            10,
            [
              "step",
              ["get", "sizerank"],
              18,
              9,
              12
            ],
            18,
            [
              "step",
              ["get", "sizerank"],
              18,
              9,
              16
            ]
          ],
          "text-max-angle": 30,
          "text-letter-spacing": [
            "match",
            ["get", "class"],
            "ocean",
            0.25,
            [
              "sea",
              "bay"
            ],
            0.15,
            0
          ],
          "text-font": [
            "DIN Offc Pro Italic",
            "Arial Unicode MS Regular"
          ],
          "symbol-placement": "line-center",
          "text-pitch-alignment": "viewport",
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ]
        },
        "paint": {
          "text-color": [
            "match",
            ["get", "class"],
            [
              "bay",
              "ocean",
              "sea"
            ],
            "#c7e5fa",
            "#3a4ca6"
          ]
        }
      },
      {
        "id": "water-point-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "natural_label",
        "filter": [
          "all",
          [
            "match",
            ["get", "class"],
            [
              "bay",
              "ocean",
              "reservoir",
              "sea",
              "water"
            ],
            true,
            false
          ],
          [
            "==",
            [
              "geometry-type"
            ],
            "Point"
          ]
        ],
        "layout": {
          "text-line-height": 1.3,
          "text-size": [
            "interpolate", ["linear"], ["zoom"],
            7,
            [
              "step",
              ["get", "sizerank"],
              24,
              6,
              18,
              12,
              12
            ],
            10,
            [
              "step",
              ["get", "sizerank"],
              18,
              9,
              12
            ]
          ],
          "text-font": [
            "DIN Offc Pro Italic",
            "Arial Unicode MS Regular"
          ],
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ],
          "text-letter-spacing": [
            "match",
            ["get", "class"],
            "ocean",
            0.25,
            [
              "bay",
              "sea"
            ],
            0.15,
            0.01
          ],
          "text-max-width": [
            "match",
            ["get", "class"],
            "ocean",
            4,
            "sea",
            5,
            [
              "bay",
              "water"
            ],
            7,
            10
          ]
        },
        "paint": {
          "text-color": [
            "match",
            ["get", "class"],
            [
              "bay",
              "ocean",
              "sea"
            ],
            "#c7e5fa",
            "#3a4ca6"
          ]
        }
      },
      {
        "id": "settlement-subdivision-label",
        "type": "symbol",
        "metadata": {
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ],
        },
        "source": "where",
        "source-layer": "place_label",
        "minzoom": 10,
        "maxzoom": 15,
        "filter": [
          "all",
          [
            "==",
            ["get", "class"],
            "settlement_subdivision"
          ],
          [
            "<=",
            ["get", "filterrank"],
            4
          ]
        ],
        "layout": {
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ],
          "text-transform": "uppercase",
          "text-font": [
            "DIN Offc Pro Regular",
            "Arial Unicode MS Regular"
          ],
          "text-letter-spacing": [
            "match",
            ["get", "type"],
            "suburb",
            0.15,
            [
              "quarter",
              "neighborhood"
            ],
            0.1,
            0.1
          ],
          "text-max-width": 7,
          "text-padding": 3,
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.5, 0, 1, 1],
            [
              "zoom"
            ],
            11,
            [
              "match",
              ["get", "type"],
              "suburb",
              11,
              [
                "quarter",
                "neighborhood"
              ],
              10.5,
              10.5
            ],
            15,
            [
              "match",
              ["get", "type"],
              "suburb",
              17,
              [
                "quarter",
                "neighborhood"
              ],
              16,
              16
            ]
          ]
        },
        "paint": {
          "text-halo-color": "#ffffff",
          "text-halo-width": 1,
          "text-color": "#3f4873",
          "text-halo-blur": 0.5
        }
      },
      {
        "id": "settlement-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "place_label",
        "maxzoom": 17,
        "filter": [
          "all"
        ],
        "layout": {
          "symbol-sort-key": ["get", "my_rank"],
          "icon-image": [
            "case",
            ["==", ["get", "capital"], 2], "border-dot-13",
            [
              "step",
              ["get", "my_rank"],
              "dot-11",
              9,
              "dot-10",
              11,
              "dot-9"
            ]
          ],
          "text-font": [
            "step",
            [
              "zoom"
            ],
            ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]],
            8,
            [
              "step",
              ["get", "my_rank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              11,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]]
            ],
            10,
            [
              "step",
              ["get", "my_rank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              12,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]]],
            11,
            [
              "step",
              ["get", "my_rank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              13,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]]
            ],
            12,
            [
              "step",
              ["get", "my_rank"],
              ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]],
              15,
              ["literal", ["DIN Offc Pro Regular", "Arial Unicode MS Regular"]]
            ],
            13,
            ["literal", ["DIN Offc Pro Medium", "Arial Unicode MS Regular"]]
          ],
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ],
          "text-max-width": 7,
          "text-line-height": 1.1,
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.2, 0, 0.9, 1],
            ["zoom"],
            10,
            [
              "match", ["get", "place"],
              "city", 15,
              "town", 13,
              "suburb", 11,
              10
            ],
            14,
            16,
            16,
            40
          ]
        },
        "paint": {
          "text-color": "#232323",
          "text-halo-color": "#ffffff",
          "text-halo-width": 1,
          "text-opacity": [
            "interpolate",
            ["cubic-bezier", 0.2, 0, 0.9, 1],
            ["zoom"],
            9,
            [
              "match",
              ["get", "place"],
              "city", 1,
              "town", 0.8,
              "suburb", 0.7,
              0.6
            ],
            12, 1,
            18, 0
          ],
          "text-halo-blur": 1
        },
        "minzoom": 2
      },
      {
        "id": "country-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "place_label",
        "minzoom": 1,
        "maxzoom": 10,
        "filter": [
          "==",
          ["get", "class"],
          "country"
        ],
        "layout": {
          "icon-image": "dot-11",
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ],
          "text-line-height": 1.1,
          "text-max-width": 6,
          "text-font": [
            "DIN Offc Pro Medium",
            "Arial Unicode MS Regular"
          ],
          "text-anchor": [
            "step",
            [
              "zoom"
            ],
            [
              "coalesce",
              ["get", "text_anchor"],
              "center"
            ],
            7,
            "center"
          ],
          "text-offset": [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "text_anchor"],
              "bottom",
              [
                "literal",
                [
                  0,
                  -0.25
                ]
              ],
              "bottom-left",
              [
                "literal",
                [
                  0.2,
                  -0.05
                ]
              ],
              "left",
              [
                "literal",
                [
                  0.4,
                  0.05
                ]
              ],
              "top-left",
              [
                "literal",
                [
                  0.2,
                  0.05
                ]
              ],
              "top",
              [
                "literal",
                [
                  0,
                  0.25
                ]
              ],
              "top-right",
              [
                "literal",
                [
                  -0.2,
                  0.05
                ]
              ],
              "right",
              [
                "literal",
                [
                  -0.4,
                  0.05
                ]
              ],
              "bottom-right",
              [
                "literal",
                [
                  -0.2,
                  -0.05
                ]
              ],
              [
                "literal",
                [
                  0,
                  -0.25
                ]
              ]
            ],
            7,
            ["literal", [0, 0]]
          ],
          "text-justify": [
            "step",
            [
              "zoom"
            ],
            [
              "match",
              ["get", "text_anchor"],
              [
                "bottom",
                "top"
              ],
              "center",
              [
                "left",
                "bottom-left",
                "top-left"
              ],
              "left",
              [
                "right",
                "bottom-right",
                "top-right"
              ],
              "right",
              "center"
            ],
            7,
            "center"
          ],
          "text-size": [
            "interpolate",
            ["cubic-bezier", 0.2, 0, 0.7, 1],
            [
              "zoom"
            ],
            1,
            [
              "step",
              ["get", "symbolrank"],
              11,
              4,
              9,
              5,
              8
            ],
            9,
            [
              "step",
              ["get", "symbolrank"],
              28,
              4,
              22,
              5,
              21
            ]
          ]
        },
        "paint": {
          "icon-opacity": [
            "step",
            [
              "zoom"
            ],
            [
              "case",
              [
                "has",
                "text_anchor"
              ],
              1,
              0
            ],
            7,
            0
          ],
          "text-color": "#000",
          "text-halo-color": [
            "interpolate", ["linear"], ["zoom"],
            2,
            "rgba(255,255,255,0.75)",
            3,
            "#ffffff"
          ],
          "text-halo-width": 1.25
        }
      },
  
      {
        "id": "admin-level-Labels",
        "type": "symbol",
        "source": "where",
        "source-layer": "admin_level",
        "minzoom": 5,
        "maxzoom": 24,
        "filter": [
          "all"
        ],
        "layout": {
          "symbol-sort-key": ["-", 20, ["get", "admin_level"]],
          "text-field": ["coalesce", ["get", "name:fr"], ["get", "name"]],
          "text-size": [
            "interpolate", ["cubic-bezier", 0.2, 0, 0.9, 1], ["zoom"],
            5, ["-", 24, ["*", 3, ["get", "admin_level"]]],
            12, ["-", 40, ["*", 4, ["get", "admin_level"]]]
          ],
          "visibility": "none"
        },
        "paint": {
          "text-color": "#6b4314"
        }
      },
      {
        "id": "housenumbers",
        "type": "symbol",
        "source": "where",
        "source-layer": "housenumber",
        "minzoom": 16,
        "maxzoom": 24,
        "filter": [
          "all"
        ],
        "layout": {
          "text-field": ["get", "addr:housenumber"],
          "text-size": ["interpolate", ["linear"], ["zoom"], 16, 5, 20, 12]
        },
        "paint": {
          "text-color": ["interpolate", ["linear"], ["zoom"], 16, "#999999", 22, "#400040"]
        }
      },
      {
        "id": "poi-label",
        "type": "symbol",
        "metadata": {},
        "source": "where",
        "source-layer": "poi_label",
        "minzoom": 13,
        "filter": [
          "all"
        ],
        "layout": {
          "symbol-sort-key": ["get", "my_rank"],
          "text-optional": true,
          "icon-padding": [
            "step", [ "zoom" ], 30, 15, 20, 16, 10, 17, 3, 18, 1
          ],
          "text-padding": [
            "step", [ "zoom" ], 30, 15, 20, 16, 10, 17, 3, 18, 1
          ],
          "text-size": [
            "step", [ "zoom" ], 11, 15, 12, 18, 13
          ],
          "icon-image": [
            "step", ["zoom"],
            ["concat", ["get", "my_icon"], "-sm"],
            15, ["concat", ["get", "my_icon"], "-lg"]
          ],
          "text-font": [
            "DIN Offc Pro Medium",
            "Arial Unicode MS Regular"
          ],
          "text-offset": [ 0, 1.5 ],
          "text-anchor": [
            "step", [ "zoom" ],
            [
              "step",
              ["get", "sizerank"],
              "center",
              5,
              "top"
            ],
            17,
            [
              "step",
              ["get", "sizerank"],
              "center",
              13,
              "top"
            ]
          ],
          "text-field": [
            "coalesce",
            ["get", "name:fr"],
            ["get", "name"]
          ]
        },
        "paint": {
          "icon-opacity": 1,
          "text-halo-color": "#ffffff",
          "text-halo-width": 1,
          "text-halo-blur": 0,
          "text-color": [
            "match",
            ["get", "amenity"],
            [
              "cafe",
              "restaurant",
              "bar",
              "fast_food"
            ],
            "#ab5b2d",
            "park_like",
            "#508934",
            "education",
            "#8f833d",
            "medical",
            "#a96078",
            "#815846"
          ]
        }
      },
      {
        "id": "road-label",
        "type": "symbol",
        "metadata": {
          "text-field": "__name__",
        },
        "source": "where",
        "source-layer": "road",
        "minzoom": 10,
        "filter": [
          "step",
          [
            "zoom"
          ],
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk",
              "primary",
              "secondary",
              "tertiary"
            ],
            true,
            false
          ],
          12,
          [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk",
              "primary",
              "secondary",
              "tertiary",
              "pedestrian",
              "residential",
              "living_street"
            ],
            true,
            false
          ],
          15,
          [
            "match",
            ["get", "highway"],
            "golf",
            false,
            true
          ]
        ],
        "layout": {
          "text-size": [
            "interpolate", ["linear"], ["zoom"],
            10,
            [
              "match",
              ["get", "highway"],
              ["motorway", "trunk", "primary", "secondary", "tertiary"],
              10,
              ["motorway_link", "trunk_link", "primary_link", "secondary_link", "tertiary_link", "pedestrian", "residential", "living_street"],
              3,
              6.5
            ],
            18,
            [
              "match",
              ["get", "highway"],
              ["motorway", "trunk", "primary", "secondary", "tertiary"],
              16,
              ["motorway_link", "trunk_link", "primary_link", "secondary_link", "tertiary_link", "pedestrian", "residential", "living_street"],
              14,
              13
            ]
          ],
          "text-max-angle": 30,
          "text-font": [
            "DIN Offc Pro Regular",
            "Arial Unicode MS Regular"
          ],
          "symbol-placement": "line",
          "symbol-spacing": 200,
          "text-padding": 0,
          "text-ignore-placement": false,
          "text-rotation-alignment": "map",
          "text-pitch-alignment": "viewport",
        },
        "paint": {
          "text-color": [
            "match",
            ["get", "highway"],
            "ferry",
            "#3a4ca6",
            "#000"
          ],
          "text-halo-color": [
            "match",
            ["get", "highway"],
            [
              "motorway",
              "trunk"
            ],
            "hsla(0, 0%, 100%, 0.75)",
            "ferry",
            "#75cff0",
            "#ffffff"
          ],
          "text-halo-width": 1,
          "text-halo-blur": 1
        }
      }
    ],
  }
}
;



style.style.layers.forEach(l => {
  if (l.metadata && l.metadata['text-field']) {
    let json = JSON.stringify(l.metadata['text-field']);
    l.layout['text-field'] = JSON.parse(json.replace('"__name__"', '["get","name"]'));
  }
  if (!l.layout) l.layout = {};
  if (!l.layout.visibility) l.layout.visibility = 'visible';
  if (!l.paint) l.layout = {};
})
export default style;
