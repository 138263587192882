class ControlTogglerControl {
  
  constructor(options) {
    this.control = options.control;
    this.position = options.position || 'top-left';
    this.label = options.label;
  }

  onAdd(map) {
    const self = this;

    this.container = document.createElement('div');
    this.container.classList.add('mapboxgl-ctrl');
    this.container.classList.add('mapboxgl-ctrl-group');

    const button = document.createElement('button');
    button.textContent = this.label;
    button.addEventListener('click', function () {
      if (button.classList.contains('active')) {
        button.classList.remove('active');
        map.removeControl(self.control);
      } else {
        button.classList.add('active');
        map.addControl(self.control, self.position);
      }
    });

    self.container.appendChild(button);
    return this.container;
  }

  onRemove() {
      this._container.parentNode.removeChild(this._container);
      this.map = undefined;
  }
}
  
export default ControlTogglerControl;  