/**
 * All layers
 */


import { 
    GoogleMapMaker,
    OpenStreetMap,
    OpenStreetMapFr,
    VirtualEarthSatellite,
    StamenArt,
    EsriTopo,
    ViaMichelin,
    NatGeo,
    MapBoxDark,
    MapBoxOutdoors,
    MapBoxSatellite,
    MapBoxStreets
} from "./thirdParty.js";


import WhereStreets from './default.js';
import Wireframe from './wireframe.js';

export const AllStyles = {
    WhereStreets,
    Wireframe,
    MapBoxStreets,
    MapBoxOutdoors,
    MapBoxDark,
    MapBoxSatellite,
    OpenStreetMap,
    OpenStreetMapFr,
    GoogleMapMaker,
    VirtualEarthSatellite,
    StamenArt,
    EsriTopo,
    ViaMichelin,
    NatGeo,
};

export default AllStyles;

