import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Mapbox from "mapbox-gl";

Mapbox.setRTLTextPlugin('/js/mapbox-gl-rtl-text.js');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "public" */'@/views/About.vue'),
  },
  {
    path: '/glossary',
    name: 'glossary',
    component: () => import(/* webpackChunkName: "public" */'@/views/Glossary.vue'),
  },
  {
    path: '/osm-stats',
    name: 'stats',
    component: () => import(/* webpackChunkName: "public" */'@/views/OsmStats.vue'),
  },
  {
    path: '/resources',
    name: 'resources',
    component: () => import(/* webpackChunkName: "public" */'@/views/Resources.vue'),
  },
  {
    path: '/style-editor',
    name: 'style-editor',
    component: () => import(/* webpackChunkName: "public" */'@/views/StyleEditor.vue'),
  },
  {
    path: '/tag-editor',
    name: 'tag-editor',
    component: () => import(/* webpackChunkName: "osm" */ '@/views/TagEditor.vue'),
  },
  {
    path: '/tags/extracted',
    name: 'tags-extracted',
    component: () => import(/* webpackChunkName: "osm" */ '@/views/TagsExtracted.vue'),
  },
  {
    path: '/names',
    name: 'Names',
    component: () => import(/* webpackChunkName: "osm" */'@/views/Names.vue'),
  },
  {
    path: '/tilesMapper',
    name: 'tilesMapper',
    component: () => import('@/views/TilesMapper.vue'),
  },
  {
    path: '/uploader',
    name: 'uploader',
    component: () => import('@/views/Uploader.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
