import $$ from './helper.js';

class LayersControl {

  layers = [];

  onAdd(map) {
    this.map = map;
    this.categories = {
      'Labels': 'label',
      'Roads': 'road-',
      'POIs': 'poi-',
      'Places': 'settlement',
      'Landuse': 'landuse',
      'Boundaries': 'admin-',
      '3D': '3d',
    }
    this.insertControls();

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map.removeLayer('streets');
    this.map.removeLayer('streets-labels');
    this.map.removeSource('streets');
    this.map = undefined;
  }

  insertControls() {
    this.container = $$.container('mapboxgl-ctrl-layers');

    const button = document.createElement('button');
    const icon = document.createElement('span');
    icon.className = 'mapboxgl-ctrl-icon';
    button.appendChild(icon);
    this.container.appendChild(button);

    this.map.on('style.load', () => {
      this.container.querySelectorAll('label').forEach(l => l.remove());
      this.insertCategories();
      this.insertLayers();
    });

    this.insertCategories();
    this.insertLayers();

  }

  insertLayers() {
    const self = this;

    this.map.getStyle().layers.forEach(function (layer) {
      const label = document.createElement('label');
      label.textContent = layer.id;

      const cb = document.createElement('input');
      cb.setAttribute('type', 'checkbox');
      cb.checked = true;
      cb.label = label;
      cb.layerId = layer.id;
      cb.addEventListener('change', function () {
        self.map.setLayoutProperty(this.layerId, 'visibility', this.checked ? 'visible' : 'none');
        this.label.className = this.checked ? 'active' : '';
      });

      label.appendChild(cb);
      self.container.appendChild(label);
    });
  }

  insertCategories() {
    const self = this;

    for (let cat in this.categories) {
      const label = document.createElement('label');
      label.className = 'cat';
      label.textContent = cat;

      const cb = document.createElement('input');
      cb.setAttribute('type', 'checkbox');
      cb.checked = true;
      cb.label = label;
      cb.value = this.categories[cat];
      cb.addEventListener('change', function () {
        self.map.getStyle().layers
        .filter(layer => layer.id.indexOf(this.value) >= 0)
        .forEach(layer => {
          self.map.setLayoutProperty(layer.id, 'visibility', this.checked ? 'visible' : 'none');
          if (this.checked) 
            this.label.classList.add('active');
            else
            this.label.classList.remove('active');
        });
      });

      label.appendChild(cb);
      self.container.appendChild(label);
    }
  }
}

export default LayersControl;
