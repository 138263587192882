
const config = {

    api: {
        port: 3111,
        url: 'https://api.where.tn'
    },
    tiles: {
        port: 3112,
    },
    mvt: {
        port: 8087,
        url: 'https://tiles.where.tn.lcl/mvt/{z}/{x}/{y}.mvt',
        wireframe: 'https://tiles.where.tn.lcl/mvt/wireframe/{z}/{x}/{y}.mvt',
        // glyphsUrl: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
        glyphsUrl: "https://tiles.where.tn.lcl/mvt/fonts/mapbox/{fontstack}/{range}.pbf",
    },
    paths: {
        tiles: '/NOT/SET',
        common: '/NOT/SET',
        static: '/NOT/SET',
    },
    mapbox: {
        token: 'pk.eyJ1Ijoic2VsaW1hY2hvdXIiLCJhIjoiY2tjOTFvOGg2MTBibjJ6cDhmNWhkYmFhaiJ9.qonyr0JcWzkfcQqWFtmCHQ',
    }
};

import localConfig from "./config.local.js";


export default Object.assign({}, config, localConfig);
