
const Str = {
  badChars: {
      '\u200e': 'LEFT-TO-RIGHT MARK',
      '\u200f': 'RIGHT-TO-LEFT MARK',
      '\u202c': 'POP DIRECTIONAL FORMATTING',
  },
  LTR: '\u200e',
  RTL: '\u200f',
  unicodeFrom: str => {
      const hex = str.codePointAt(0).toString(16);
      return '\\u' + '0000'.substring(0, 4 - hex.length) + hex;
  },
  stripAccents: (str) => {
      // remove latin accents;
      str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      // remove arabic diacritics
      str = str.normalize("NFD").replace(/[\u064b-\u065f]/g, "");
      return str;
  },
  copyToClipboard: (str) => {
      const el = document.createElement('textarea');
      el.style.opacity = '0';
      el.style.position = 'absolute'; 
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
  },
}

Str.reverse = s => s ? s.split('').reverse().join('') : s;

Str.sort = (a, b) => {
  a = 'string' === typeof a ? Str.stripAccents(a).toLowerCase() : '';
  b = 'string' === typeof b ? Str.stripAccents(b).toLowerCase() : '';
  if (a===b) return 0;
  if (a>b) return 1;
  return -1;
}

Str.searchIn = (a, b) => {
  a = 'string' === typeof a ? Str.stripAccents(a).toLowerCase() : '';
  b = 'string' === typeof b ? Str.stripAccents(b).toLowerCase() : '';
  
  // regexp might be invalid
  try {
    return b.match(a);
  } catch (e) {
    return false;
  }
}

Str.hasBadChars = str => {
  for (let bad in Str.badChars) {
      if ((new RegExp(bad)).test(str)) 
      return true;
  }
  return false;
}

Str.download = (content, fileName) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default Str;