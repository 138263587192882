

function xyToBbox(z,x,y) {
    const bounds = tileBounds(z,x,y);
    const mins = metersToLatLng(bounds[0]);
    const maxs = metersToLatLng(bounds[1]);
        
    return {
        minLat: mins[1],
        maxLat: maxs[1],
        minLng: mins[0],
        maxLng: maxs[0]
    };
}

function metersToLatLng(coord) {
    let lng = (coord[0] / (2 * Math.PI * 6378137 / 2.0)) * 180.0
    
    let lat = (coord[1] / (2 * Math.PI * 6378137 / 2.0)) * 180.0
    lat = 180 / Math.PI * (2 * Math.atan( Math.exp( lat * Math.PI / 180.0)) - Math.PI / 2.0)
    
    return [lng,lat]
}


function tileBounds(z,x,y) {
    var mins = pixelsToMeters( z, x*256, (y+1)*256 )
    var maxs = pixelsToMeters( z, (x+1)*256, y*256 )
        
    return [mins,maxs];
}


function pixelsToMeters(z,x,y) {
    var res = (2 * Math.PI * 6378137 / 256) / (Math.pow(2,z));
    let mx = x * res - (2 * Math.PI * 6378137 / 2.0);
    let my = y * res - (2 * Math.PI * 6378137 / 2.0);
    my = -my;
    return [mx, my];
}

function lngLatZoomToTileXY(lng, lat, zoom) {
    const MinLatitude = -85.05112878,
          MaxLatitude = 85.05112878,
          MinLongitude = -180,
          MaxLongitude = 180;

    let longitude = clip(lng, MinLongitude, MaxLongitude)
    let latitude  = clip(lat, MinLatitude, MaxLatitude)

    const p = {};
    p.x = ((longitude + 180.0) / 360.0) * (1 << zoom)
    p.y = (1.0 - Math.log(Math.tan(latitude * Math.PI / 180.0) + 1.0 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2.0 * (1 << zoom)

    const tilex  = parseInt(Math.trunc(p.x));
    const tiley  = parseInt(Math.trunc(p.y));

    return {
        x: tilex,
        y: tiley
    };

    function clip(n,minValue,maxValue) {
        return Math.min(Math.max(n, minValue), maxValue);
    }
}

export const Misc = {
    isMobile: () => {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];
    
        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
}

export default {
    lngLatZoomToTileXY,
    xyToBbox,
}