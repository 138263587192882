import { sphere } from "./spheres";
import { circle, cylinder } from "./circles";
import { triangle } from "./triangles";
import { corrugatedQuad, polygon } from "./surfaces";

const Shapes = {
    triangle,
    sphere,
    circle,
    cylinder,
    corrugatedQuad,
    polygon
};

export default Shapes;