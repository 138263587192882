/**
 * QuadKeys: https://docs.microsoft.com/en-us/bingmaps/articles/bing-maps-tile-system
 * @see https://docs.microsoft.com/en-us/bingmaps/articles/
 */


export const MapBoxStreets = {
    label: 'Mapbox Streets',
    style: 'mapbox://styles/mapbox/streets-v11',
    tags: ['Mapbox', 'osm', 'roads'],
};

export const MapBoxDark = {
    label: 'Dark',
    style: 'mapbox://styles/mapbox/dark-v10',
    tags: ['Mapbox', 'osm', 'roads'],
};

export const MapBoxOutdoors = {
    label: 'Outdoors',
    style: 'mapbox://styles/mapbox/outdoors-v11',
    tags: ['Mapbox', 'osm', 'roads'],
};

export const MapBoxSatellite = {
    label: 'Satellite',
    style: 'mapbox://styles/mapbox/satellite-v9',
    tags: ['Mapbox', 'maxar', 'satellite'],
};

export const GoogleMapMaker = {
    label: 'Google Map Maker',
    desc: 'Equivalent to Google Map Streets',
    tags: ['Google', 'roads'],
    style: {
        version: 8,
        sources: {
            GoogleMapMaker: {
                type: 'raster',
                tiles: [
                    'https://mt1.google.com/vt/hl=x-local&source=mapmaker&x={x}&y={y}&z={z}',
                    'https://mt2.google.com/vt/hl=x-local&source=mapmaker&x={x}&y={y}&z={z}',
                    'https://mt3.google.com/vt/hl=x-local&source=mapmaker&x={x}&y={y}&z={z}',
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 22,
                attribution: 'Map tiles and data by <a href="https://www.google.com/maps/" target="google_street">Google</a>',
            }
        },
        layers: [
            {
                id: 'GoogleMapMaker',
                type: 'raster',
                source: 'GoogleMapMaker',
                minzoom: 0,
                maxzoom: 22
            }
        ]
    }
};

export const OpenStreetMap = {
    label: 'OpenStreetMap',
    desc: 'Default tiles found on the main website',
    tags: ['osm', 'roads'],
    style: {
        version: 8,
        sources: {
            OpenStreetMap: {
                type: 'raster',
                tiles: [
                    'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
                attribution: 'Map tiles and data by <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            }
        },
        layers: [
            {
                id: 'OpenStreetMap',
                type: 'raster',
                source: 'OpenStreetMap',
            }
        ]
    }
}

export const OpenStreetMapFr = {
    label: "OpenStreetMap.fr",
    desc: 'Default tiles found on the main website',
    tags: ['osm', 'roads'],
    style: {
        version: 8,
        sources: {
            OpenStreetMapFr: {
                type: 'raster',
                tiles: [
                    'https://a.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
                    'https://b.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png',
                    'https://c.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png'
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
                attribution: 'Map tiles and data by <a href="https://www.openstreetmap.org/" target="_blank">OpenStreetMap</a>',
            }
        },
        layers: [
            {
                id: 'OpenStreetMapFr',
                type: 'raster',
                source: 'OpenStreetMapFr',
            }
        ]
    }
};

export const VirtualEarthSatellite = {
    label: "VirtualEarth Satellite",
    tags: ['satellite'],
    style: {
        version: 8,
        sources: {
            VirtualEarthSatellite: {
                type: "raster",
                tiles: [
                    "https://t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=en-US&it=A,G,RL&shading=hill&n=z&og=677&c4w=1&src=h",
                    "https://t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=en-US&it=A,G,RL&shading=hill&n=z&og=677&c4w=1&src=h",
                    "https://t.ssl.ak.dynamic.tiles.virtualearth.net/comp/ch/{quadkey}?mkt=en-US&it=A,G,RL&shading=hill&n=z&og=677&c4w=1&src=h"
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 18,
                attribution: "Map tiles and data by <a href=\"http://www.viavirtualearth.com/\" target=\"_ve\">Virtual Earth</a>"
            }
        },
        layers: [
            {
                id: "VirtualEarthSatellite",
                type: "raster",
                source: "VirtualEarthSatellite",
            }
        ]
    }
};

export const StamenArt = {
    label: "Stamen Art",
    tags: ['osm', 'art'],
    style: {
        version: 8,
        sources: {
            StamenArt: {
                type: "raster",
                tiles: [
                    "https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 16,
                attribution: "Map tiles by <a target=\"_stamen\" rel=\"noopener\" href=\"http://stamen.com\">Stamen Design</a>, under <a target=\"_top\" rel=\"noopener\" href=\"http://creativecommons.org/licenses/by/3.0\">CC BY 3.0</a>. Data by <a target=\"_top\" rel=\"noopener\" href=\"http://openstreetmap.org\">OpenStreetMap</a>, under <a target=\"_top\" rel=\"noopener\" href=\"http://creativecommons.org/licenses/by-sa/3.0\">CC BY SA</a>"
            }
        },
        layers: [
            {
                id: "StamenArt",
                type: "raster",
                source: "StamenArt",
            }
        ]
    }
};

export const EsriTopo = {
    label: "Esri Topo",
    tags: ['esri', 'topo',],
    style: {
        version: 8,
        sources: {
            EsriTopo: {
                type: "raster",
                tiles: [
                    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}.jpg"
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 13,
                attribution: "Map tiles and data by <a href=\"https://www.esri.com/\" target=\"esri\">Esri</a>"
            }
        },
        layers: [
            {
                id: "EsriTopo",
                type: "raster",
                source: "EsriTopo",
            }
        ]
    }
};

export const ViaMichelin = {
    label: "Via Michelin",
    tags: ['raster'],
    style: {
        version: 8,
        sources: {
            ViaMichelin: {
                type: "raster",
                tiles: [
                    "https://map1.viamichelin.com/map/mapdirect?map=viamichelin&z={z}&x={x}&y={y}&format=png&version=201906241753&layer=background&locale=default&cs=1&protocol=https",
                    "https://map2.viamichelin.com/map/mapdirect?map=viamichelin&z={z}&x={x}&y={y}&format=png&version=201906241753&layer=background&locale=default&cs=1&protocol=https",
                    "https://map3.viamichelin.com/map/mapdirect?map=viamichelin&z={z}&x={x}&y={y}&format=png&version=201906241753&layer=background&locale=default&cs=1&protocol=https"
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 19,
                attribution: 'Map tiles and data by <a href="https://www.viamichelin.com/" target="_michelin">ViaMichelin</a>',
            }
        },
        layers: [
            {
                id: 'ViaMichelin',
                type: 'raster',
                source: 'ViaMichelin',
            }
        ]
    }
};

export const NatGeo = {
    label: "National Geographic",
    tags: ['esri', 'raster'],
    style: {
        version: 8,
        sources: {
            NatGeo: {
                type: "raster",
                tiles: [
                    "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
                    "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
                    "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
                ],
                tileSize: 256,
                minzoom: 0,
                maxzoom: 12,
                attribution: "Map tiles  by <a href=\"https://www.esri.com/news/arcuser/0312/national-geographic-basemap.html\" target=\"_esri\">Esri / National Geographic</a>"
            }
        },
        layers: [
            {
                id: "NatGeo",
                type: "raster",
                source: "NatGeo",
            }
        ]
    }
};


