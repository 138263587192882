
const Shaders = {


    vertexSource: {
        type: 'VERTEX_SHADER',
        src: `
            uniform mat4 u_mapbox_matrix;

            uniform vec4 u_eye_high;
            uniform vec4 u_eye_low;

            attribute vec3 a_high_pos;
            attribute vec3 a_low_pos;
            attribute vec3 a_normal;

            varying highp vec3 v_normal;
            
            void main() {
                // compute position
                gl_Position  = vec4(a_high_pos - u_eye_high.xyz, 0);
                gl_Position += vec4(a_low_pos  - u_eye_low.xyz,  0);
                gl_Position = u_mapbox_matrix * gl_Position;
                gl_Position.w += u_eye_high.w;
                
                // lighting
                v_normal = a_normal;
            }
        `
    },

    fragmentSimpleColor: {
        type: 'FRAGMENT_SHADER',
        src: `
            precision mediump float;
            
            varying vec3 v_normal;
            
            uniform vec3 u_reverseLightDirection;
            uniform vec4 u_color;
            
            void main() {
                vec3 normal = normalize(v_normal);
                
                float light = dot(normal, normalize(u_reverseLightDirection));
                
                gl_FragColor = u_color;
                
                // Lets multiply just the color portion (not the alpha)
                // by the light
                gl_FragColor.rgb *= light;
            }
        `
    },

    _src2vars: (gl, program, src) =>{
        const vars = {};
        Array.from(src.match(/\b[au]_[a-zA-Z_]+/g)).forEach(v => {
            if ('u' === v[0]) vars[v] = gl.getUniformLocation(program, v);
            else if ('a' === v[0]) vars[v] = gl.getAttribLocation(program, v)

        });
        return vars;
    },

    load: (gl, shaders) => {
        const program = gl.createProgram();
        const sources = [];

        shaders.forEach(shaderName => {
            const def = Shaders[shaderName];
            const shader = gl.createShader(gl[def.type]);
            gl.shaderSource(shader, def.src);
            gl.compileShader(shader);
            const success = gl.getShaderParameter(shader, gl.COMPILE_STATUS);
            if (!success) {
                throw "could not compile shader:" + gl.getShaderInfoLog(shader);
            }
            gl.attachShader(program, shader);
            sources.push(def.src);
        })
        gl.linkProgram(program);

        return {
            program, 
            vars: Shaders._src2vars(gl, program, sources.join('\n'))
        };
    }

}

export default Shaders;
