
import { lusolve, divide, flatten } from "mathjs";
import mapboxgl from "mapbox-gl";

import Shaders from "./Shaders";
import Shapes from "./shapes";

const FLOAT_SIZE = 4;
const FLOATS_PER_VERTEX = 3;

class zLayer {

    program; // shader
    vertexArray = []; // [x,y,z, X,Y,Z, ...]
    normalArray = []; // [x,y,z, X,Y,Z, ...]
    objects = [];

    constructor(id, { defaultColor }) {
        this.id = id;
        this.type = 'custom';
        this.renderingMode = "3d";
        this.defaultColor = defaultColor || [1, 1, 1, 1];

        // register addShapes
        for (let name in Shapes) {
            const fn = 'add' + name[0].toUpperCase() + name.slice(1);
            this[fn] = (props) => this.addShape(Shapes[name](props));
        }
    }

    addShape({vertices, normals, objects}) {
        const index = this.vertexArray.length / 6;
        for (let i=0; i<vertices.length; i+=3) {
            this.vertexArray = this.vertexArray.concat(this._add_point(vertices[i], vertices[i+1], vertices[i+2]));
        }
        for (let i=0; i<normals.length; i++) {
            this.normalArray = this.normalArray.concat(normals[i].toArray());
        }

        objects.forEach(o => {
            this.objects.push(Object.assign({}, o, { start: o.start + index }));
        }) 

    }

    onAdd(map, gl) {

        const { program, vars } = Shaders.load(gl, ["vertexSource", "fragmentSimpleColor"]);
        this.program = program;
        this.vars = vars;

        this.vertexBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this.vertexArray), gl.STATIC_DRAW);

        this.normalBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this.normalBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this.normalArray), gl.STATIC_DRAW);
    }

    render(gl, mapboxMatrix) {
        gl.useProgram(this.program);
        const vars = this.vars;

        // fix studdering        
        const eye_high = this._get_eye(mapboxMatrix);
        const eye_low  = eye_high.map(e => e - Math.fround(e));
        gl.uniform4fv(vars.u_eye_high, eye_high);
        gl.uniform4fv(vars.u_eye_low,  eye_low);
        gl.uniform3fv(vars.u_reverseLightDirection, [-0.1, 0, 0.1]);
        gl.uniformMatrix4fv(vars.u_mapbox_matrix, false, mapboxMatrix);

        // bind vertices
        gl.bindBuffer(gl.ARRAY_BUFFER, this.vertexBuffer);
        gl.enableVertexAttribArray(vars.a_high_pos);
        gl.vertexAttribPointer(vars.a_high_pos, 3, gl.FLOAT, false, 2 * FLOAT_SIZE * FLOATS_PER_VERTEX,  0);
        gl.enableVertexAttribArray(vars.a_low_pos);
        gl.vertexAttribPointer(vars.a_low_pos,  3, gl.FLOAT, false, 2 * FLOAT_SIZE * FLOATS_PER_VERTEX, FLOAT_SIZE * FLOATS_PER_VERTEX);

        // bind normals
        gl.bindBuffer(gl.ARRAY_BUFFER, this.normalBuffer);
        gl.enableVertexAttribArray(vars.a_normal);
        gl.vertexAttribPointer(vars.a_normal, 3, gl.FLOAT, false, 0, 0);
        
        // GL options
        gl.enable(gl.BLEND);
        gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
        
        // Draw Objects
        for (let i of this.objects) {
            const color = i.color || this.defaultColor;
            gl.uniform4f(vars.u_color, color[0], color[1], color[2], color[3] );
 
            // set the light direction.
            gl.drawArrays(gl[i.mode], i.start, i.count);
        }
    }

    
    /************* shortcuts ***********/

    _get_eye(mat) {
        mat = [[mat[0], mat[4], mat[8], mat[12]], [mat[1], mat[5], mat[9], mat[13]], [mat[2], mat[6], mat[10], mat[14]], [mat[3], mat[7], mat[11], mat[15]]];
        var eye = lusolve(mat, [[0], [0], [0], [1]]);
        var clip_w = 1.0 / eye[3][0];
        eye = divide(eye, eye[3][0]);
        eye[3][0] = clip_w;
        return flatten(eye);
    }


    _add_point(lat, lng, height) {
        var coord = mapboxgl.MercatorCoordinate.fromLngLat({ lat, lng });
        height = height || 0;
        return [
            coord.x, 
            coord.y,
            height / 60000000,
            coord.x - Math.fround(coord.x), 
            coord.y - Math.fround(coord.y),
            height / 60000000
        ];
    }
}

export default zLayer;
