<template>
  <span @click="onclick" :class="{ disabled: !!disabled }">
    <Icon :icon="icon" v-bind="$attrs"/>
  </span>
</template>

<script>
export default {
  props: ['icon', 'disabled'],
  methods: {
    onclick(e) {
      if (!this.disabled)
        this.$emit('click', e);
    }
  }
}
</script>

<style scoped>
span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.3em;
  height: 1.3em;
  box-sizing: content-box;
  border: 1px solid transparent;
  filter: grayscale(0.4) opacity(0.8);
  cursor: pointer;
}

span.disabled {
  filter: grayscale(1) blur(0.8px) opacity(0.5);
}

span.disabled {
  cursor: not-allowed;
}

span:not(.disabled):hover {
  filter: grayscale(0.1) opacity(0.9);
  /* border-color: #aaa; */
}
span:not(.disabled):active {
  filter: grayscale(0) opacity(1);
  /* border-color: #aaa; */
}

</style>