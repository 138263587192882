/**
 * 
 * @todo Fix circle which looks like an ellipse
 */

import earcut from "earcut";
import v3 from "../v3";


export const corrugatedQuad = function ({ a, b, c, d, periods, periodResolution, h, color, wireframe }) {
    let vertices = [];
    const objects = [];

    let index;

    const dLat1 = (b.lat - a.lat) / (periods * periodResolution);
    const dLng1 = (b.lng - a.lng) / (periods * periodResolution);
    const dLat2 = (c.lat - d.lat) / (periods * periodResolution);
    const dLng2 = (c.lng - d.lng) / (periods * periodResolution);

    if (wireframe) {
        index = vertices.length / 6;
        for (let i = 0; i <= (periods * periodResolution); i++) {
            vertices = vertices.concat([
                a.lat + i * dLat1,
                a.lng + i * dLng1,
                a.height + (h * Math.sin(i * (2 * Math.PI / periodResolution)))
            ]);
        }
        objects.push({
            mode: 'LINE_STRIP',
            start: index,
            count: (periodResolution * periods),
            color: color || [1, 0, 0, 1]
        });

        index = vertices.length / 6;
        for (let i = 0; i <= (periods * periodResolution); i++) {
            vertices = vertices.concat([
                d.lat + i * dLat2,
                d.lng + i * dLng2,
                d.height + (h * Math.sin(i * (2 * Math.PI / periodResolution)))
            ]);
        }
        objects.push({
            mode: 'LINE_STRIP',
            start: index,
            count: (periodResolution * periods),
            color: color || [0, 0, 0, 1]
        });
    }


    index = vertices.length / 6;
    for (let i = 0; i <= (periods * periodResolution); i++) {
        vertices = vertices.concat([
            a.lat + i * dLat1,
            a.lng + i * dLng1,
            a.height + (h * Math.sin(i * (2 * Math.PI / periodResolution)))
        ]);
        vertices = vertices.concat([
            d.lat + i * dLat2,
            d.lng + i * dLng2,
            d.height + (h * Math.sin(i * (2 * Math.PI / periodResolution)))
        ]);
    }

    objects.push({
        mode: wireframe ? 'LINES' : 'TRIANGLE_STRIP',
        start: index,
        count: (periodResolution * periods) * 2,
        color: color || [0, 0, 0, 1]
    });

    return { vertices, objects };
}


export const polygon = function ({ coordinates, color, wireframe }) {
    let vertices = [];
    let objects;

    if (wireframe) {
        for (let c of coordinates) {
            vertices = vertices.concat( [c.lat, c.lng, c.height] );
        }
        vertices = vertices.concat( [coordinates[0].lat, coordinates[0].lng, coordinates[0].height] );
        objects = [{
            mode: 'LINE_STRIP',
            start: 0,
            count: coordinates.length + 1,
            color: color || [1, 1, 1, 0]
        }];

    } else {

        const input = [];
        for (let c of coordinates) {
            input.push(c.lat, c.lng, c.height);
        }
        const indices = earcut(input, null, 3);
    
        for (let i = 0; i < indices.length; i += 3) {
            const a = coordinates[indices[i]];
            const b = coordinates[indices[i + 1]];
            const c = coordinates[indices[i + 2]];
            vertices = vertices.concat(
                [a.lat, a.lng, a.height],
                [b.lat, b.lng, b.height],
                [c.lat, c.lng, c.height],
            );
        }
    
        objects = [{
            mode: 'TRIANGLES',
            start: 0,
            count: indices.length,
            color: color || [1, 1, 1, 0]
        }];
    }

    let normals = [];
    vertices.forEach(() => normals.push(new v3(0,0,1)))

    return { vertices, normals, objects };
}