import $$ from './helper.js';

class QueryFeatureControl {
  
  constructor() {
  }

  onAdd(map) {
    const self = this;

    const ui = this._ui(map);

    map.on('click', function(e) {
      if (map.queryFeatureOnClick) {
        const features = e.target.queryRenderedFeatures(e.point);
        const display = self.getData(features);
        self.displayData(display);
      }
    });

    map.on('mousemove', function(e) {
      if (map.queryFeatureOnClick) {
        const features = e.target.queryRenderedFeatures(e.point);
        const display = self.getData(features);
        map.getCanvas().style.cursor = display.length ? 'help' : 'crosshair';
      }
    });

    return ui;
  }

  onRemove() {
      this._container.parentNode.removeChild(this._container);
      this.cb(null);
      this.map.getCanvas().style.cursor = '';
      this.map = undefined;
  }

  getData(features) {
    return features
      .map(f => {
        return JSON.stringify({
          layer: f.layer.id, ...f.properties
        })
      })
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(j => JSON.parse(j));
  }

  _ui(map) {

    this.table = document.createElement('div');
    this.table.setAttribute('id', 'features');
    this.table.style.display = 'none';
    document.querySelector('div.mapboxgl-map').appendChild(this.table);


    this.container = $$.container('mapboxgl-ctrl-query');

    const button = $$.button('?');
    button.title = 'Query Feature by Click';

    button.addEventListener('click', () => {
      if (button.classList.contains('active')) {
        button.classList.remove('active');
        map.getCanvas().style.cursor = '';
        map.queryFeatureOnClick = false;
        this.displayData(null);
      } else {
        button.classList.add('active');
        map.queryFeatureOnClick = true;
      }
    });

    this.container.appendChild(button);
    return this.container;
  }

  // display the table
  displayData(data) {

    this.table.innerHTML = '';

    if (null === data || !data.length) {
      this.table.style.display = 'none';
      return;
    }

    data.forEach(feature => {
      const table = document.createElement('table');

      Object.keys(feature).forEach(key => {

        const val = feature[key];

        // create row and cells
        const tr = document.createElement('tr');
        const th = document.createElement('td');
        const td = document.createElement('td');

        // fill with default values
        th.innerText = key;
        td.innerText = val;

        // specific tweaks
        switch (key) {
          case 'layer':
            th.innerText = 'Layer';
            td.style.color = 'cyan';
            break;
          case 'osm_id':
            var type = feature.layer.match(/water|admin_level|barrier|road|landuse|building/) ? 'way' : 'node';
            td.innerHTML = `<a target="_blank" href="https://openstreetmap.org/${type}/${val}">${val}</a>`;
        }

        // append row
        tr.appendChild(th);
        tr.appendChild(td);
        table.appendChild(tr);
      });

      this.table.appendChild(table);
    })

    this.table.style.display = 'block';
//            <td title="Edit layer" style="color: cyan;">{{feature.layer}}</td>
//    <span @click="clickedFeatures = null">[close]</span>
  }
}
  
export default QueryFeatureControl;  