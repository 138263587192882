

function addMobilePitchGesture(mbMap) {
  const minDiffX = 70; // min x distance to recognize pitch gesture
  const maxDiffY = 100; // max y distance to recognize pitch gesture
  const minDiff = 30; // min distance to recognize zoom gesture
  const delay = 160; // delay for pitch, in case it's a zoom gesture

  let dpPoint;
  let dpPitch;
  let startTiming;
  let startDistance;
  let startEventData;

  mbMap
  .on("touchstart", data => {
    if (data.points.length === 2) {
      const diffY = data.points[0].y - data.points[1].y;
      const diffX = data.points[0].x - data.points[1].x;
      if (Math.abs(diffX) >= minDiffX && Math.abs(diffY) <= maxDiffY) {
        data.originalEvent.preventDefault(); // prevent browser refresh on pull down
        mbMap.touchZoomRotate.disable(); // disable native touch controls
        mbMap.dragPan.disable();
        dpPoint = data.point;
        dpPitch = mbMap.getPitch();
        startTiming = Date.now();
        startDistance = Math.hypot(diffX, diffY);
        startEventData = data;
      }
    }
  })
  .on("touchmove", data => {
    if (dpPoint !== undefined && dpPitch !== undefined) {
      data.preventDefault();
      data.originalEvent.preventDefault();

      const diffY = data.points[0].y - data.points[1].y;
      const diffX = data.points[0].x - data.points[1].x;
      const distance = Math.hypot(diffX, diffY);

      if (Math.abs(distance - startDistance) >= minDiff) {
        if (dpPoint) {
          mbMap.touchZoomRotate.enable();
          mbMap.dragPan.enable();
          mbMap.touchZoomRotate.onStart(
            Date.now() - startTiming >= delay
              ? data.originalEvent
              : startEventData.originalEvent
          );
        }
        dpPoint = undefined;
        return;
      }

      if (Date.now() - startTiming >= delay) {
        const diff = (dpPoint.y - data.point.y) * 0.5;
        mbMap.setPitch(dpPitch + diff);
      }
    }
  })
  .on("touchend", () => {
    if (dpPoint) {
      mbMap.touchZoomRotate.enable();
      mbMap.dragPan.enable();
    }
    dpPoint = undefined;
  })
  .on("touchcancel", () => {
    if (dpPoint) {
      mbMap.touchZoomRotate.enable();
      mbMap.dragPan.enable();
    }
    dpPoint = undefined;
  });
}


export {
  addMobilePitchGesture
};
