import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import CONFIG from '../common/config';

import '@/services/components';

// API configuration

import axios from 'axios'
const api = axios.create({
  baseURL: CONFIG.api.url,
});
// for general errors display
api.interceptors.response.use(
  success => { store.state.nbApiCalls--; return success; }, 
  error   => { store.state.nbApiCalls--; return error; });

// authentication
api.interceptors.request.use((config) => { store.state.nbApiCalls++;  return config; });


Vue.prototype.$api = api;


// Start App

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
