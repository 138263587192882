import Vue from 'vue';


// icons ...
// https://fontawesome.com/icons?d=gallery&m=free
import { library } from '@fortawesome/fontawesome-svg-core'

import {     faPlus, faPlusSquare, faRegistered, faCopy, faUndo, faLanguage, faMapMarkedAlt, faRobot, faSearch, faPaste, faEdit, faTrash, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
library.add( faPlus, faPlusSquare, faRegistered, faCopy, faUndo, faLanguage, faMapMarkedAlt, faRobot, faSearch, faPaste, faEdit, faTrash, faEye, faEyeSlash )

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('Icon', FontAwesomeIcon)

import ActionIcon from '@/components/ActionIcon';
Vue.component('ActionIcon', ActionIcon);