

const v3 = function(x, y, z) {
  this.x = x;
  this.y = y;
  this.z = z;

  this.minus = (v) => {
    return new v3(
      this.x - v.x, 
      this.y - v.y, 
      this.z - v.z
    );
  }

  this.plus = (v) => {
    return new v3(
      this.x + v.x, 
      this.y + v.y, 
      this.z + v.z
    );
  }

  this.times = (v) => {
    return new v3(
      this.y * v.z - this.z * v.y,
      this.z * v.x - this.x * v.z,
      this.x * v.y - this.y * v.x
    );
  }

  this.length = () => Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2) + Math.pow(this.z, 2));

  this.normalize = () => {
    const l = this.length();
    this.x /= l;
    this.y /= l;
    this.z /= l;
    return this;
  }

  this.clone = () => new v3(this.x, this.y, this.z);
  this.toArray = () => [this.x, this.y, this.z];

  return this;
};



export default v3;
