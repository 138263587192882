

import $$ from './helper.js';

import Str from "@/common/helpers/Str.js";

class SearchControl {

  map;
  container; 
  q = '';
  error = false;
  elements = {};
  features = [];

  constructor() {
    const options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.styles = options.styles;
  }

  onAdd(map) {
    this.map = map;
    this.addSourceAndLayers();
    this.insertControls();
    const self = this;
    map.on('styledata', () => { self.addSourceAndLayers.apply(self) });
    map.on('moveend', () => { self.onSearchTermChanged.apply(self) });

    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map.removeLayer('streets');
    this.map.removeLayer('streets-labels');
    this.map.removeSource('streets');
    this.map = undefined;
  }

  insertControls() {
    const self = this;

    // container
    this.container = $$.container('mapboxgl-ctrl-search');
    this.container.innerHTML = `
      <svg class="mapboxgl-ctrl-geocoder--icon mapboxgl-ctrl-geocoder--icon-search" viewBox="0 0 18 18" xml:space="preserve" width="18" height="18">
        <path d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z"></path>
      </svg>
    `;

    // search input
    this.input = document.createElement('input');
    this.input.setAttribute('type', 'search');
    this.input.setAttribute('placeholder', 'Search ...');

    this.input.addEventListener('input', function() {
      self.q = this.value;
      self.onSearchTermChanged();
    });
    this.input.addEventListener('focus', () => this.container.classList.add('child-focus'));
    this.input.addEventListener('blur', () => {
      setTimeout(() => this.container.classList.remove('child-focus'), 300);
    });
    self.container.appendChild(this.input);

    // error
    this.elements.error = document.createElement('div');
    this.elements.error.className = 'err';
    self.container.appendChild(this.elements.error);

    // result
    this.elements.result = document.createElement('ul');
    self.container.appendChild(this.elements.result);
    this.elements.result.addEventListener('mouseleave', () => {
      this.map.setPaintProperty('x-streets', 'line-color', "#00f");
      this.map.setPaintProperty('x-streets', 'line-width', 3);
    })
  }

  onSearchTermChanged() {

    if (this.q.trim().length === 0) {
      this.updateResults([]);
      return;
    }

    if (this.q.trim().length < 2) {
      return;
    }

    const slef = this;
    clearTimeout(slef.searchTimeout);
    slef.searchTimeout = setTimeout(() => slef.searchStreets.apply(this), 300);
  }

  searchStreets() {

    const q = Str.stripAccents(this.q);

    try {
      new RegExp(q);
      this.error = '';
    } catch (e) {
      this.error = 'Invalid RegExp';
      return;
    }

    const bbox = this.map.vue.getBBox();
    this.map.vue.searching = true;
    this.map.vue.$api
      .get(`/db/search?bbox=${bbox}&q=${q}`)
      .then(j => {
        this.map.vue.searching = false;
        this.updateResults(j.data.features);
      })
  }

  addSourceAndLayers() {
    // layers already exists
    if (this.map.getLayer('x-streets'))
      return;

    this.map.addSource('x-streets', { 'type': 'geojson', data: null });
    this.map.addLayer({
      'id': 'x-streets',
      'source': 'x-streets',
      'type': 'line',
      'layout': { 'line-join': 'round', 'line-cap': 'round' },
      'paint': { 'line-color': '#00f', 'line-width': 3, 'line-opacity': 0.5 }
    });
    this.map.addLayer({
      'id': 'x-streets-labels',
      'source': 'x-streets',
      'type': 'symbol',
      'layout': {
        'text-field': ["step", [ "zoom" ], "", 13, [ "get", "short_name" ], 17, ['coalesce', ['get', 'name_fr'], ['get', 'name']]],
        'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
        "text-size": [ "interpolate", [ "linear" ], [ "zoom" ], 13, 8, 18, 13],
        "text-padding": 0,
        'text-offset': [0, 0.6],
        "text-rotation-alignment": "map",
        "text-pitch-alignment": "viewport",
        "symbol-placement": "line",
        "symbol-spacing": 150,
        'text-allow-overlap': true
      },
      'paint': {
        'text-color': '#00a',
        "text-halo-color": "hsl(0, 0%, 100%)",
        "text-halo-blur": 0.5,
        "text-halo-width": 0.5
      }
    });

  }

  updateResults(features) {
    const self = this;
    this.result = features;
    this.map.getSource('x-streets').setData({type: "FeatureCollection", features});

    this.elements.result.innerHTML = '';
    if (features.length === 0) {
      if (this.q.length > 1) {
        const li = document.createElement('li');
        li.className = 'empty';
        li.innerText = "No Results";
        this.elements.result.appendChild(li);
      }
    }

    features
    .sort((a, b) => {
      const A = a.properties.name_fr || a.properties.name || '';
      const B = b.properties.name_fr || b.properties.name || '';
      return A > B ? 1 : -1;
    })
    .forEach(feature => {
      let label = feature.properties.name_fr || feature.properties.name || '';
      if (!label) return;


      const li = document.createElement('li');
      const highlight = this.q.replace(/[eé]/g, '[eé]');
      label = label.replace(new RegExp(`(${highlight})`, 'ig'), '<strong>$1</strong>');
      li.innerHTML = label;
      //.replace('Rue ', 'r. ').replace('Avenue ', 'av. ').replace('Boulevard ', 'blvd. ');

      if (feature.properties.is_in) {
        const isin = document.createElement('i');
        isin.innerText = feature.properties.is_in.replace(/Délégation|Gouvernorat/g, '').replace(/\//g, ', ');
        li.appendChild(isin);
      }


      this.elements.result.appendChild(li);
      li.id = feature.properties.osm_id;
      li.feature = feature;

      li.addEventListener('mouseenter', (e) => {
        this.map.setPaintProperty('x-streets', 'line-color', 
          [ "match", [ "get", "osm_id" ], e.srcElement.id, "#f00", "#00f"]);
        this.map.setPaintProperty('x-streets', 'line-width', 
          [ "match", [ "get", "osm_id" ], e.srcElement.id, 10, 3]);
      })

      li.addEventListener('click', function() {
        self.map.setPaintProperty('x-streets', 'line-color', "#aaf");
        self.map.setPaintProperty('x-streets', 'line-width', 8);
        self.flyTo(this.feature);
      })
    })
  }

  flyTo(feature) {
    let minLon = Infinity;
    let minLat = Infinity;
    let maxLon = -Infinity;
    let maxLat = -Infinity;
    
    feature.geometry.coordinates.forEach(line => {
      line.forEach(c => {
        if (c[0] < minLon) minLon = c[0];
        if (c[0] > maxLon) maxLon = c[0];
        if (c[1] < minLat) minLat = c[1];
        if (c[1] > maxLat) maxLat = c[1];
      })
    })
    self.map.fitBounds([[minLon, minLat], [maxLon, maxLat]], {
      padding: 50,
      maxZoom: 17
    })
  }

}

export default SearchControl;
