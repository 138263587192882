/**
 * Shortcut for importing all controls
 */

import ExternalEditorsControl from "./ExternalEditorsControl";
import LanguageSelectorControl from "./LanguageSelectorControl";
import StyleSelectorControl from "./StyleSelectorControl";
import ShowTilesCoordinatesControl from "./ShowTilesCoordinatesControl";
import QueryFeatureControl from "./QueryFeatureControl";
import ControlTogglerControl from "./ControlTogglerControl";
import SearchControl from "./SearchControl";
import LayersControl from "./LayersControl";

export default {
  ExternalEditorsControl,
  LanguageSelectorControl,
  StyleSelectorControl,
  ShowTilesCoordinatesControl,
  QueryFeatureControl,
  ControlTogglerControl,
  SearchControl,
  LayersControl,
};